import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

function ModalRegistrarIniciar() {

    

   

    const navigate = useNavigate();
    const [archivo, setArchivo] = useState(null); // imagen subida
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal

    
    


    

    return(
        <div>
            <Button  variant="primary" onClick={handleShow} style={{width: "100%"}}>
                Comprar NFT
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Comprar NFT</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <p>Para poder comprar un NFT en Native, debes ser usuario. Por favor selecciona una de las siguientes opciones.</p>
                    
                </Modal.Body>

                {/* Footer */}
                <Modal.Footer>
                    <a class="btn btn-primary w-100" href="/iniciar-sesion" role="button">Iniciar Sesión</a>
                    <a class="btn btn-secondary w-100" href="/registrar" role="button">Registrar</a>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalRegistrarIniciar;