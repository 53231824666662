import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Web3 from 'web3';
// Replace with the URL of your Ganache instance
var web3 

function VerUsuario(){
    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    const navigate = useNavigate();
    const [nftsUsuario, setNftsUsuario] = useState([]);
    let urlEtherscan = "https://etherscan.io/";
    let urlSepolia   = "https://sepolia.etherscan.io/";
    let urlAusar     = urlEtherscan; // cambiar a producción

    const location = useLocation();
    const idUsuario       = location.state.idUsuario;
    const usernameUsuario = location.state.usernameUsuario;
    const usuarioActual   = location.state.usuarioActual;


    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////




        async function obtenerNFTSdb(){
            const datosAenviar = {username: usernameUsuario};
            await axios.post("/obtener-nfts-db", datosAenviar)
            .then(response => {
                // Si ha salido bien la respuesta.
                if(response.status===200){
                    const NFTS = response.data.nftsEncontrados;
                    setNftsUsuario(NFTS); // ponemos los nfts encontrados en usestate.
                }   
            })            
        }
        obtenerNFTSdb();



        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);

    

    return(
        <div className='contenedorPaginasUsuario'>
            


            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                        <h1 className="h2 text-light">Ver usuario</h1>
                    </div>

                    {/* AVATAR e información */}
                    <div className="contenedorUno p-5 mb-4 rounded-3">
                        <div className="d-flex justify-content-center text-light mb-1"><h3>Información de usuario</h3></div>
                        <div className="d-flex justify-content-center my-5"> <img className="imgAvatar" alt="avatar" src={usuarioActual.avatar} /></div>
                    
                        {/* información */}
                        <div className="container">
                            {/* Fila 1 */}
                            <div className="row mt-2">
                                {/* Usuario */}
                                <div className="col-lg-2">
                                    <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 pt-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end"  >
                                        <h6 className="quitarMBh6">Usuario</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                        <h6 className="quitarMBh6">{usuarioActual.username}</h6>
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="col-lg-2">
                                    <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end">
                                        <h6 className="quitarMBh6">Email</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                        <h6 className="quitarMBh6">{usuarioActual.email}</h6>
                                    </div>
                                </div>
                            </div>
                            

                            {/* Fila 2 */}
                            <div className="row mt-2">
                                {/* Nombre */}
                                <div className="col-lg-2">
                                    <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 pt-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end"  >
                                        <h6 className="quitarMBh6">Nombre</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                        <h6 className="quitarMBh6">{usuarioActual.nombre}</h6>
                                    </div>
                                </div>

                                {/* Apellido */}
                                <div className="col-lg-2">
                                    <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end">
                                        <h6 className="quitarMBh6">Apellidos</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                        <h6 className="quitarMBh6">{usuarioActual.apellido}</h6>
                                    </div>
                                </div>
                            </div>


                            {/* Fila 3 */}
                            <div className="row mt-2">
                                {/* Identificación */}
                                <div className="col-lg-2">
                                    <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 pt-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end"  >
                                        <h6 className="quitarMBh6">Identificación</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                        <h6 className="quitarMBh6">{usuarioActual.identificacion}</h6>
                                    </div>
                                </div>

                                {/* Telefono */}
                                <div className="col-lg-2">
                                    <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end">
                                        <h6 className="quitarMBh6">Teléfono</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                        <h6 className="quitarMBh6">{usuarioActual.telefono}</h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Lista nfts */}
                    <div className="contenedorUno p-5 mb-4 rounded-3">
                        <div className="d-flex justify-content-center text-light mb-1"><h3>NFTS</h3></div>
                        <div className="d-flex justify-content-center text-light mb-1"><p>Aquí podrás ver los NFTS de este usuario</p></div>

                        { (nftsUsuario.length>0)
                            ? nftsUsuario.map( (nftActual, key) => (
                                <div key={nftActual.idNFT} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                    {/* Avatar */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <img src={nftActual.imagenNFT} alt="avatar" style={{width: "80px", borderRadius: "50%"}} />
                                        </div>
                                    </div>

                                    {/* id */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={nftActual.idNFT}  />
                                            <label htmlFor="floatingInputGrid">Id NFT</label>
                                        </div>
                                    </div>

                                    {/* Nombre Propiedad */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={nftActual.nombrePropiedad}  />
                                            <label htmlFor="floatingInputGrid">Propiedad</label>
                                        </div>
                                    </div>

                                    {/* Fecha de compra */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={nftActual.historial[nftActual.historial.length-1].fecha.dia + "/" + nftActual.historial[nftActual.historial.length-1].fecha.mes + "/" + nftActual.historial[nftActual.historial.length-1].fecha.año }  />
                                            <label htmlFor="floatingInputGrid">Fecha</label>
                                        </div>
                                    </div>

                                    {/* Precio */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={Web3.utils.fromWei(nftActual.precio.toString(), 'Ether') + " ETH"}   />
                                            <label htmlFor="floatingInputGrid">Precio</label>
                                        </div>
                                    </div>


                                    {/* Tarifa ETH en USD */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue= {"$" + nftActual.historial[nftActual.historial.length-1].valor}  />
                                            <label htmlFor="floatingInputGrid">Tarifa ETH</label>
                                        </div>
                                    </div>

                                    {/* Costo NFT en USD */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$" + (nftActual.historial[nftActual.historial.length-1].valor * Number(Web3.utils.fromWei(nftActual.precio.toString(), 'Ether')) ).toFixed()  }  />
                                            <label htmlFor="floatingInputGrid">NFT en USD</label>
                                        </div>
                                    </div>

                                    {/* Hash transacción */}
                                    <div className="col-sm-3">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={nftActual.historial[nftActual.historial.length-1].hash} />
                                            <label htmlFor="floatingInputGrid">Hash transacción</label>
                                        </div>
                                    </div>

                                    {/* Botón ver transacción */}
                                    <div className="col-sm-2">
                                        <a className="btn btn-primary btn-lg w-100" target="_blank" rel="noreferrer" href={ urlAusar + "tx/" + nftActual.historial[nftActual.historial.length-1].hash } role="button">Ver hash</a>
                                    </div>
                                </div>
                            ))
                            : <div className="d-flex justify-content-center text-light mb-1"><p>Este usuario aún no tiene NFTS</p></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerUsuario;