import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams } from "react-router-dom";
import Web3 from 'web3';
import axios from "axios";
import {create} from 'ipfs-http-client'; // solo para subir imagenes a ipfs, pero se debe cambiar por helia o bien usar infura
// Replace with the URL of your Ganache instance
var web3 


function CrearPropiedades(){
    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    const navigate = useNavigate();
    // Capturamos el valor de params
    const routeParams = useParams();
    let urlVolver = "/admin/crear-propiedades/" + routeParams.username;

    // useState blockchain
    const [estado, setEstado] = useState({
        account: "",
        cantidadTokens: 0,
        arregloTokensPropiedades: [],
        loading: true,
        abiContrato: "",
        addressContrato: "",
        contratoEnBlockchain: "",
    });

        
    useEffect(()  =>  {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // // función para conectar con metamask: abre ventana extensión para conectar
        async function loadWeb3(){
            window.addEventListener('load', async () => {
            // FORMA1: Modern dapp browsers... - chrome
            if (window.ethereum) {
                // crea una nueva conexión a la blockchain. Se pasa como argumento window.ethereum. Luego lo hbilitamos
                try {
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    setEstado(prevEstado => { return { ...prevEstado, account: accounts[0], } });  // Actualizamos account con el valor obtenido, PERO mantenimiento los otros valores anteriores.
                } catch (error) {
                    if (error.code === 4001) {
                        // User rejected request
                        console.log("error 4001");
                    }
                }
            }
        
            // FORMA2: Legacy dapp browsers...
            else if (window.web3) { window.web3 = new Web3(web3.currentProvider); }
        
            // FORMA3: Non-dapp browsers...
            else {console.log('No se detectó un navegador que no es Ethereum. ¡Deberías considerar probar MetaMask!');}
            });
        }

        // // Obtener cuentas para seleccionar cuál se usará
        async function loadBlockchainData() {
            const conexionWeb3 = new Web3(window.ethereum); // conectamos y almacenamos conexión a web3


            //Cargar contrato en la blockchain desde "servidor/blockchain"
            await axios.post("/obtener-contrato")
            .then(async function(response){
                // Actualizamos los valores de abi y adress en el estado, pro si los necesitamos.
                setEstado(prevEstado => { return {...prevEstado, abiContrato: response.data.abi, addressContrato: response.data.address, } });
                // Creamos variables ABI y ADDRESS dinámicas
                const contratoMarketplace = response.data.contrato; // obtenemos el contrato
                const networkId   = await conexionWeb3.eth.net.getId(); // obtenemos el id para determinar a que red estamos conectados.
                const networkData = contratoMarketplace.networks[networkId]; // buscamos la red con base en el id anterior.

                if(networkData){
                    const marketplaceEnBlockchain = new conexionWeb3.eth.Contract(contratoMarketplace.abi, networkData.address); // Agregamos el contrato a la blockchain. - agregamos gas
                    setEstado(prevEstado => { return { ...prevEstado, contratoEnBlockchain: marketplaceEnBlockchain, loading: false } }); // guardamos el contrato que ya está en la blocjchain, en el estado, junto con loading. 
                    let cantidadTokens = Number(await marketplaceEnBlockchain.methods.cantidadTokens().call()); // ejecutamos la cantidad de tokens
                    setEstado(prevEstado => { return { ...prevEstado, cantidadTokens: cantidadTokens } }); // guardamos la cantidad de tokens en el estado
                    
                    //console.log("Cantidad de tokens: ", cantidadTokens);
                    //console.log("arreglo propuedades: ", estado.arregloTokensPropiedades, " y cantidad de tokens: ", cantidadTokens);


                    // REVISAMOS ARREGLO PARA MOSTRAR PROPIEDADES CREADAS
                    for(var i=1; i<=cantidadTokens; i++){
                        let propiedadActual = await marketplaceEnBlockchain.methods.tokensPropiedades(i).call(); // cargamos la propiedad que esta´iterando.
                        setEstado(prevEstado => { return { ...prevEstado, arregloTokensPropiedades: [...prevEstado.arregloTokensPropiedades, propiedadActual] } }); // prevESTADO se usa tanto para el estado completo, como para el arrelgo interno, y de esa manera, actualizamos en la ulitma pos y mantenimos elr esto.
                    }

                    
                }
                else{ window.alert('El contrato de Marketplace no se implementó en la red detectada.'); }
            });


        }

        loadWeb3();
        loadBlockchainData();

        

        
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


   

    // FORMULARIO /////////////////////////////
    // Creamos usestate y control de cambio para los datos del fumulario e imagen, de forma independiente
    const[input, setInput] = useState ({
        nombrePropiedad: "",
        precioToken: 0
    });
    function handleChange(event){
        const {name, value} = event.target;
        setInput(prevInput => { return { ...prevInput, [name]: value } });
    }

    const [imagenNFT, setImagenNFT] = useState(null); // imagen nft independiente
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImagenNFT(file);
    };
    // Creamos usestate y control de cambio para los datos del fumulario e imagen, de forma independiente


    // Función formulario
    async function ejecutarCrearToken(event){
        event.preventDefault(); // evita el parpadeo predefinido

        if(estado.contratoEnBlockchain){
            // Subimos imagen a aws s3
            const formData = new FormData(); // Creamos formData para pasar la imagen sin problema.
            formData.append('file', imagenNFT); // guardamos imagen en 'file'
            
            // Enviamos a subir la imagen
            await axios.post("/subida-imagenes-nfts", formData, { headers: { 'Content-Type': 'multipart/form-data',},})
            .then(async function(response){
                console.log(response);
                if(response.status===200){
                    
                    // IMPRESIONES
                    //console.log(urlImagenEnS3, + "desde respuesta");
                    // console.log("contrato en blockchain: ", estado.contratoEnBlockchain);
                    // console.log("estado.account: ", estado.account);
                    // console.log(" input.nombrePropiedad: ", infoAusar.nombrePropiedad,  ". y tipo: ", typeof( infoAusar.nombrePropiedad));
                    // console.log("precioToken: ", infoAusar.precioToken, ". y tipo: ", typeof(infoAusar.precioToken));
                    const cuenta        = estado.account;
                    const nombre        = input.nombrePropiedad;
                    const precio        = Web3.utils.toWei(input.precioToken, 'ether');
                    const urlImagenEnS3 = response.data.urlImagen; // recuperamos url ya alojada en s3 del nft.
                    
                    try{
                        await estado.contratoEnBlockchain.methods.crearToken(nombre, precio, urlImagenEnS3).send({from: cuenta, gas: 310000  }).once('receipt', (receipt) =>{
                            //console.log('Resultado de transacción:', receipt);
                            // console.log("Arreglo propuedades: ", estado.arregloTokensPropiedades, " y cantidad de tokens: ", estado.cantidadTokens);
                            //console.log("propiedad creada: ", receipt.events.TokenPropiedadCreado.returnValues.id); // return values contiene la información del nft recién creado
                            const timestamp = Number(receipt.events.TokenPropiedadCreado.returnValues.timestamp); // Tu timestamp aquí
                            const fecha = new Date(timestamp * 1000);  // Crear un nuevo objeto Date utilizando el timestamp
                            const dia = fecha.getDate();
                            const mes = fecha.getMonth() + 1; 
                            const año = fecha.getFullYear();

                            // Ejecutar creación de nft en base de datos
                            axios.post("/crear-nft-db", {
                                headers: { 'Content-Type': 'multipart/form-data',},
                                params: {
                                    hash: receipt.transactionHash,
                                    diaCreacion: dia,
                                    mesCreacion: mes,
                                    añoCreacion: año, 
                                    idNFT: receipt.events.TokenPropiedadCreado.returnValues.id, 
                                    nombrePropiedad: nombre,
                                    precio: precio,
                                    direccionPropietario: cuenta,
                                    imagenNFT: urlImagenEnS3,
                                    timestamp: receipt.events.TokenPropiedadCreado.returnValues.timestamp
                                }
                            }).then(function(response){
                                if(response.status===200){ 
                                    console.log("creado correctamente"); 
                                    window.location.reload(); // reiniciamos página.
                                }
                            });
                        });
                    } 
                    catch (error) {
                        console.error('Error sending transaction:', error);
                        if (error.response && error.response.data) {
                            console.error('RPC response data:', error.response.data);
                        }
                    }
                }
                else{ return navigate("/alerta", {state: {titulo:"Error", mensaje:"Hay un error al actualizar. Por favor volver a intenar.", urlMensaje: urlVolver, nombreBoton:"Volver"} }); }
            });                  
        } 
    }
    // FORMULARIO /////////////////////////////

    // Funcion para crear nfts no creados, solo usado 1 vez
    // function crearDB(event){
    //     console.log("entro");
    //     console.log("Arreglo propuedades: ", estado.arregloTokensPropiedades, " y cantidad de tokens: ", estado.cantidadTokens);


    //     axios.post("/crear-nfts-ya-creados", estado.arregloTokensPropiedades)
    //     .then(response => {
    //         console.log('Respuesta del servidor:', response.data);
    //       })
    //       .catch(error => {
    //         console.error('Error al enviar los datos:', error);
    //       });

    // }
    
   
    //console.log("Arreglo propuedades: ", estado.arregloTokensPropiedades, " y cantidad de tokens: ", estado.cantidadTokens);
    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                        <h1 className="h2 text-light">Crear propiedades</h1>
                    </div>

                    {/* Contenedor principal */}
                    <div className="contenedorUno p-5 mb-4 rounded-3">
                        <div className="container-fluid py-5">
                            <h1 className="display-5 fw-bold text-light">Propiedad y Tokens</h1>
                            <p className="col-md-8 fs-4 text-light">Crea la cantidad de tokens que desees para una única propiedad.</p>
                            <h5 className="text-light">Importante</h5>
                            <p className="text-light">
                                - El contrato es inmutable, por ende, una vez creada la propiedad, no se puede modificar.<br/>
                                - El nombre de la propiedad debe escribirse completo: unidad 13, lote 1009, casa 506.<br/>
                                - La cantidad de tokens debe escribirse en número.<br/>
                                - El precio o valor de cada token debe ser en ETH.
                            </p>

                            {/* Dirección */}
                            <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text text-light" style={{backgroundColor: "#7b714a"}} id="inputGroup-sizing-sm">Dirección actual</span>
                                <input readOnly style={{backgroundColor: "#ada06b"}} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" value={estado.account} />
                            </div>

                             {/* FORMULARIO ////////////////////////////////////////////*/}
                            <form>
                                {/* Propiedad */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.nombrePropiedad} name="nombrePropiedad" type="text" className="form-control" id="floatingInput" placeholder="unidad 19" />
                                    <label htmlFor="floatingInput">Nombre de la propiedad</label>
                                </div>
                                {/* Valor por token */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.precioToken} name="precioToken" type="text" className="form-control" id="floatingInput3" placeholder="10" />
                                    <label  htmlFor="floatingInput3">Valor de token (ETH)</label>
                                </div>
                                {/* Imagen */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleImageChange} name="imagenNFT"  type="file" className="form-control" accept=".jpg" id="formFile"  /> 
                                    <label  htmlFor="floatingInput3">Imagen</label>
                                </div>


                                {/* Botón enviar */}
                                <button onClick={ejecutarCrearToken} type="submit" style={{width: "100%"}} className="btn btn-primary">Crear propiedad y tokens</button>
                            </form>
                            {/* FORMULARIO ////////////////////////////////////////////*/}
                        </div>
                    </div>

                    {/* <button onClick={crearDB}>CREAR EN DB</button> */}


                     {/* Lista propiedades creadas */}
                     <div className="contenedorUno p-5 mb-4 rounded-3">
                        <div className="container-fluid py-5">
                            <h1 className="display-5 fw-bold text-light">Propiedades ya creadas</h1>
                            <p className="col-md-8 fs-4 text-light">Aqui puedes ver las propiedades ya creadas.</p>
                            <p className="text-light mb-5">luego cre realizar la creación, las siguientes propiedades pasan a estar listas par su compra y venta únicamente por los usuarios de Native</p>



                            {/* Dirección */}
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">No de token</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Precio</th>
                                        <th scope="col">Dirección Propietario</th>
                                        <th scope="col">Imagen</th>
                                        <th scope="col">Propietario</th>
                                        <th scope="col">Fecha</th>
                                    </tr>
                                </thead>
                                <tbody id="productList">
                                  
                                


                                {estado.arregloTokensPropiedades.map( (propiedadActual, key) =>{
                                    return(
                                        <tr key={key} >
                                            {<th scope="row">{propiedadActual.id.toString()}</th> }
                                            {<td>{propiedadActual.nombrePropiedad}</td>}
                                            {<td>{Web3.utils.fromWei(propiedadActual.precio.toString(), 'Ether')} Eth</td> }
                                            {<td>{propiedadActual.direccionPropietario}</td> }
                                            {<td><img src={propiedadActual.imagenNFT} alt="Imagen nft" style={{width: "80px"}} /></td> }
                                            {<td>{propiedadActual.usuarioPropietario}</td> }
                                            {<td>{propiedadActual.timestamp}</td> }
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default CrearPropiedades;