import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
//import { useParams } from "react-router-dom";

function Soporte() {
    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    // Declaramos useLocation para traer props en navigate
    const location = useLocation();
    const navigate = useNavigate();

    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    
 
    // Capturamos el valor de params
    //const routeParams = useParams();
    // console.log(routeParams);
    // console.log("location: ", location.state);

    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarUsuario />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                        <h1 className="h2 text-light">Soporte</h1>
                    </div>
            
                </div>
            </div>
        </div>
    )
}



export default Soporte;