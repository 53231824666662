import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Web3 from 'web3';
var web3 

function Comprar() {
    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    // Declaramos useLocation para traer props en navigate
    const location = useLocation();
    const navigate = useNavigate();

    // Capturamos el valor de params
    const routeParams = useParams();
    //console.log(routeParams);
    const enlaceVolverHomeUsuario = "/cliente/home/" + routeParams.username; 
    const enlaceComoUsar = "/como-funciona"; 
    // Capitalizar y eliminar guiones de nombre de propiedad
    const nombrePropiedad = routeParams.propiedad
    .split('-') // Divide el string en un array usando guiones como delimitador
    .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1)) // Capitaliza cada palabra
    .join(' '); // Une las palabras en un nuevo string separado por espacios
    // console.log("location: ", location.state);


    
    


    // useState blockchain
    const [estado, setEstado] = useState({
        account: "0xa0de40217cddbcf52787191793203bfe34f1cf8c",
        cuentaComprador: "",
        cantidadTokens: 0,
        arregloTokensPropiedades: [],
        loading: true,
        abiContrato: "",
        addressContrato: "",
        contratoEnBlockchain: "",
        precioPorToken: 0,
    });

    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////



        // // función para conectar con metamask: abre ventana extensión para conectar
        async function loadWeb3(){
            window.addEventListener('load', async () => {
            // FORMA1: Modern dapp browsers... - chrome
            if (window.ethereum) {
                console.log("entro a 1");
                // crea una nueva conexión a la blockchain. Se pasa como argumento window.ethereum. Luego lo hbilitamos
                try {
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    setEstado(prevEstado => { return { ...prevEstado, cuentaComprador: accounts[0], } });  // Actualizamos account con el valor obtenido, PERO mantenimiento los otros valores anteriores.
                } catch (error) {
                    if (error.code === 4001) {
                        // User rejected request
                        console.log("error 4001");
                    }
                }
            }
        
            // FORMA2: Legacy dapp browsers...
            else if (window.web3) { window.web3 = new Web3(web3.currentProvider); }
        
            // FORMA3: Non-dapp browsers...
            else {
                //console.log('No se detectó un navegador que no es Ethereum. ¡Deberías considerar probar MetaMask!');
                return navigate("/alerta", {state: {titulo:"Instala Metamask", mensaje:"Para poder comprar un NFT en native, debes instalar metamask en tu navegador. Haz click a continuación para ver cómo hacerlo.", urlMensaje: enlaceComoUsar, nombreBoton:"Cómo instalar"} } );
            }
            });
        }



        // // Obtener cuentas para seleccionar cuál se usará
        async function loadBlockchainData() {
            try{
                const conexionWeb3 = new Web3(window.ethereum); // conectamos y almacenamos conexión a web3
                //const conexionWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_INFURAMAINNET));

                //Cargar contrato en la blockchain desde "servidor/blockchain"
                await axios.post("/obtener-contrato")
                .then(async function(response){
                    // Actualizamos los valores de abi y adress en el estado, pro si los necesitamos.
                    setEstado(prevEstado => { return {...prevEstado, abiContrato: response.data.abi, addressContrato: response.data.address, } });
                    // Creamos variables ABI y ADDRESS dinámicas
                    const contratoMarketplace = response.data.contrato; // obtenemos el contrato
                    const networkId   = await conexionWeb3.eth.net.getId(); // obtenemos el id para determinar a que red estamos conectados.
                    const networkData = contratoMarketplace.networks[networkId]; // buscamos la red con base en el id anterior.

                    if(networkData){
                        const marketplaceEnBlockchain = new conexionWeb3.eth.Contract(contratoMarketplace.abi, networkData.address); // Agregamos el contrato a la blockchain. - agregamos gas
                        setEstado(prevEstado => { return { ...prevEstado, contratoEnBlockchain: marketplaceEnBlockchain, loading: false } }); // guardamos el contrato que ya está en la blocjchain, en el estado, junto con loading. 
                        let cantidadTokens = Number(await marketplaceEnBlockchain.methods.cantidadTokens().call()); // ejecutamos la cantidad de tokens
                        setEstado(prevEstado => { return { ...prevEstado, cantidadTokens: cantidadTokens } }); // guardamos la cantidad de tokens en el estado
                        
                        //console.log("Cantidad de tokens: ", cantidadTokens);
                        //console.log("arreglo propuedades: ", estado.arregloTokensPropiedades, " y cantidad de tokens: ", cantidadTokens);


                        // REVISAMOS ARREGLO PARA MOSTRAR PROPIEDADES CREADAS
                        // lo hago desde 1, porque desde 0, la posición 0 no tiene nada, es un nft como boceto y no se puede comprar ni usar.
                        for(var i=1; i<=cantidadTokens; i++){
                            let propiedadActual = await marketplaceEnBlockchain.methods.tokensPropiedades(i).call(); // cargamos la propiedad que esta´iterando.
                            setEstado(prevEstado => { return { ...prevEstado, arregloTokensPropiedades: [...prevEstado.arregloTokensPropiedades, propiedadActual] } }); // prevESTADO se usa tanto para el estado completo, como para el arrelgo interno, y de esa manera, actualizamos en la ulitma pos y mantenimos elr esto.
                            // Guardamos el precio aproximado para mostrar
                            if(i===2){
                                setEstado(prevEstado => { return { ...prevEstado, precioPorToken: propiedadActual.precio } });
                            }
                        }

                        // Encontrar el valor
                        
                            
                        
                        
                    }
                    else{ window.alert('El contrato de Marketplace no se implementó en la red detectada.'); }
                });
            }
            catch(error){
                //console.log("error desde conexión: ", error);
                return navigate("/alerta", {state: {titulo:"Instala Metamask", mensaje:"Para poder comprar un NFT en native, debes instalar metamask en tu navegador. Haz click a continuación para ver cómo hacerlo.", urlMensaje: enlaceComoUsar, nombreBoton:"Cómo instalar"} } );
            }


        }

        loadWeb3();
        loadBlockchainData();

        
        





        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    
 


    async function funcionComprar(datos){
        
        
        const datosCompra = {
            direccionPropietario: datos.direccionPropietario,
            idNFT: Number(datos.id),
            precio: datos.precio,
            usuarioPropietario: datos.usuarioPropietario,
            usuarioComprador: infoUsuarioLocal.usuario.username,
            direccionComprador: estado.cuentaComprador
        }


        // Si el usuario propietario es el mismo comprador, hay error
        if(datosCompra.usuarioPropietario===datosCompra.usuarioComprador){ return navigate("/alerta", {state: {titulo:"Error", mensaje:"No puedes comprar tu propio NFT. Por favor selecciona otro NFT.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } ); }
        else{
            // Si el usuario es native y ya hay una cuenta conectada para comprar, entonces proceder: 
            if(datosCompra.usuarioPropietario==="native" && datosCompra.direccionComprador!=="" ){
                
                try{
                    await estado.contratoEnBlockchain.methods.comprarTokens(datosCompra.idNFT, datosCompra.usuarioComprador).send({from: datosCompra.direccionComprador, value: datosCompra.precio, gas: 150000 }).once('receipt', (receipt) =>{
                        // receipt, ahce referencia a lo que devuelve la blockchain a ejecutar
                        // console.log('Resultado de transacción:', receipt);
                        // console.log("arreglo propuedades: ", estado.arregloTokensPropiedades, " y cantidad de tokens: ", estado.cantidadTokens);
                        const datosCompraEnviar = {...datosCompra, hash: receipt.transactionHash, email: infoUsuarioLocal.usuario.email, nombre: infoUsuarioLocal.usuario.nombre, apellido: infoUsuarioLocal.usuario.apellido};
                        axios.post("/comprar-base-datos", datosCompraEnviar)
                        .then(response => {
                            // Si ha salido bien la respuesta.
                            if(response.status===200){
                                return navigate("/alerta", {state: {titulo:"Transacción exitosa", mensaje:"La transacción se ha ejecutado correctmente. Por favor espera unos minutos a que esta se confirme y luego podrás verificar en tus propiedades.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );
                            }
                            else{
                                return navigate("/alerta", {state: {titulo:"NFT Comprado pero error en nuestra base de datos", mensaje:"La transacción se ha ejecutado correctmente en blockchain pero hay un error en nuestra base de datos. Por favor, contactar a soporte.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );
                            }       
                        })
                        .catch(error => { console.error('Error al enviar los datos:', error); });
                    });
                } 
                catch (error) {
                    return navigate("/alerta", {state: {titulo:"Error", mensaje:"Ha habido un error en la transacción. Por favor haz click para volver a home. Revisa tu wallet y verifica el saldo antes de volver a intentar. Adicional, escríbenos para darte soporte.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );
                }   
            }
            // Si el usuario comprador es otro usuario y ya hay una cuenta conectada para comprar, entonces proceder: 
            else if(datosCompra.usuarioPropietario!=="native" && datosCompra.direccionComprador!==""){

                // si no tiene errores el usuario entonces pasamos datos a servidor o bd.
                axios.post("/comprar-nft-otro-usuario", datosCompra)
                .then(function (response) {
                    let respuesta = response.data.respuesta;
                    // Si está en proceso, es denegada o ha sido creada mostramos el mensaje
                    if(respuesta==="proceso" || respuesta==="denegar" || respuesta==="creada"){
                        return navigate("/alerta", {state: {titulo: response.data.titulo, mensaje: response.data.mensaje, urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );
                    }
                    else if(respuesta==="aceptar"){
                        let infoNFT = response.data.idNFT;
                        //console.log(infoNFT);
                        comprarOtroUsuario(datosCompra);  // lo pongo por fuera porque no deja ejecutar await en un nivel tan bajo del if.
                    }
                    else{ return navigate("/alerta", {state: {titulo: "Error", mensaje: "Por favor intenta de nuevo enviar la solicitud.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );  }
                })
                .catch(function(err){console.log(err);});
            }
        }
    }

    async function comprarOtroUsuario(datosCompra){

        try{
            await estado.contratoEnBlockchain.methods.comprarTokens(datosCompra.idNFT, datosCompra.usuarioComprador).send({from: datosCompra.direccionComprador, value: datosCompra.precio }).once('receipt', (receipt) =>{
                // receipt, ahce referencia a lo que devuelve la blockchain a ejecutar
                // console.log('Resultado de transacción:', receipt);
                // console.log("arreglo propuedades: ", estado.arregloTokensPropiedades, " y cantidad de tokens: ", estado.cantidadTokens);
                return navigate("/alerta", {state: {titulo:"Transacción exitosa", mensaje:"La transacción se ha ejecutado correctmente. Por favor espera unos minutos a que esta se confirme y luego podrás verificar en tus propiedades.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );
            });
        } 
        catch (error) {
            return navigate("/alerta", {state: {titulo:"Error", mensaje:"Ha habido un error en la transacción. Por favor haz click para volver a home. Revisa tu wallet y verifica el saldo antes de volver a intentar. Adicional, escríbenos para darte soporte.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );
        }   
    }

    // FUNCIÓN SOLO PARA USUARIOS QUE NO SE LES HABIA CREADO LOS PAGOS. MILENA Y JGUERRA. pero lo dejo por si lo vuelvo a necesitar.
    // Si se vuelve a usar se debe cambiar el valor de eth, fecha y todo de etherscan
    // async function crearPagosDB(event){
        
    //     // MILENA
    //     const datosCompra = {
    //         direccionPropietario: "0x960129703adE9373A5ae201424FFFED959698AAc",
    //         idNFT: 1,
    //         precio: "450000000000000000",
    //         usuarioPropietario: 'Native',
    //         usuarioComprador: 'jguerra',
    //         direccionComprador: "0x5Df6A1A3c75079E1753F672E8ea60D92265378DA"
    //     }

    //     const hash = "0x2e5d9bde0baf03a1e641451718079418291e75406f8d791fc5f7252305a8e54d";

    //     const datosCompraEnviar = {...datosCompra, hash: hash, email: "josepablo-310@hotmail.com", nombre: "Jose", apellido: "Guerra"};
    //     axios.post("/comprar-base-datos", datosCompraEnviar)
    //     .then(response => {
    //         // Si ha salido bien la respuesta.
    //         if(response.status===200){
    //             return navigate("/alerta", {state: {titulo:"Transacción exitosa", mensaje:"La transacción se ha ejecutado correctmente. Por favor espera unos minutos a que esta se confirme y luego podrás verificar en tus propiedades.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );
    //         }
    //         else{
    //             return navigate("/alerta", {state: {titulo:"NFT Comprado pero error en nuestra base de datos", mensaje:"La transacción se ha ejecutado correctmente en blockchain pero hay un error en nuestra base de datos. Por favor, contactar a soporte.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );
    //         }       
    //     })
    //     .catch(error => { console.error('Error al enviar los datos:', error); });
    // }
    


    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarUsuario />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                        <h1 className="h2 text-light">Comprar propiedades</h1>
                    </div>
            

                    {/* Contenedor resumen propiedad */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">
                        <div className="row">
                            {/* Imagen */}
                            <div className="col-lg-6">
                                    <div className="d-flex justify-content-center"> <img className="imagenPropiedadComprar" src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+1.jpg"  alt="" /></div>
                            </div>

                            {/* Resumen propiedad */}
                            <div className="col-lg-6">
                                <div className="container-fluid py-5">
                                    
                                    <div className="card mb-4 rounded-3 shadow-sm">
                                        <div className="card-header py-3" style={{backgroundColor: "#414525"}} >
                                            <h2 className="my-0 fw-normal text-light">{nombrePropiedad}</h2>
                                        </div>
                                        <div className="card-body">
                                            <h1 className="card-title pricing-card-title"><i className="fa-brands fa-ethereum" style={{fontSize:"30px"}} ></i> {Web3.utils.fromWei(estado.precioPorToken.toString(), 'Ether')} <small className="text-body-secondary fw-light">eth / unidad</small></h1>
                                            <ul className="list-unstyled mt-3 mb-4">
                                                <li><strong> Aproximado USD:</strong>  $1.000 / unidad</li>
                                                <li><strong>Cantidad de nfts:</strong>  {estado.cantidadTokens}</li>
                                                <li><strong>Rentabilidad:</strong> 12% efectivo anual.</li>
                                                <li><strong>Devolución de capital:</strong> Entre el año 3 y 4 al propietario actual del NFT. Se paga utilidad representada en USD.</li>
                                                <li>Sin cláusulas de permanencia. Compra y vende cuando desee.</li>
                                            </ul>
                                           
                                            <a className="w-100 btn btn-lg btn-primary" href="#contenedorNFTS" role="button">Ver NFTS disponibles</a>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    {/* Carrusel de fotos */}
                    <div id="fotosPropiedad" className="carousel slide mb-6" data-bs-ride="carousel" >
                        {/* Indicadores */}
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#fotosPropiedad" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#fotosPropiedad" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#fotosPropiedad" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>


                        <div className="carousel-inner" >
                            {/* Descripción 1 */}
                            <div className="carousel-item active" >
                                {/* imagen 1 */}
                                <div class="d-flex align-items-center justify-content-center h-100">
                                    <img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+2.png" className="imagenCarrusel d-block w-100" alt="..." />
                                </div>
                                <div className="container textoCarrusel">
                                    <div className="carousel-caption text-start">
                                        <h1>Ubicación.</h1>
                                        <p className="opacity-75">A tan solo hora y media de Medellín y 20 minutos de El Peñol, Antioquia.</p>
                                    </div>
                                </div>
                            </div>
                            {/* Propiedad 2 */}
                            <div className="carousel-item">
                            {/* imagen 2 */}
                                <div class="d-flex align-items-center justify-content-center h-100">
                                    <img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+3.png" className="imagenCarrusel d-block w-100" alt="..." />
                                </div>
                                <div className="container">
                                <div className="carousel-caption">
                                    <h1>Oportunidad de negocio.</h1>
                                    <p>La valorización esperada es del 50% en dos años.</p>
                                </div>
                                </div>
                            </div>
                            {/* Propiedad 3 */}
                            <div className="carousel-item">
                            {/* imagen 3 */}
                                <div class="d-flex align-items-center justify-content-center h-100">
                                    <img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+2.png" className="imagenCarrusel d-block w-100" alt="..." />
                                </div>
                                <div className="container">
                                <div className="carousel-caption text-end">
                                    <h1>Medio ambiente.</h1>
                                    <p>Construcción y arquitectura responsable.</p>
                                </div>
                                </div>
                            </div>
                        </div>

                        {/* Botones */}
                        <button className="carousel-control-prev" type="button" data-bs-target="#fotosPropiedad" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#fotosPropiedad" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>



                    {/* Contenedor información propiedad */}
                    <div className="pt-5 px-5 mb-5 bg-body-tertiary rounded-3">
                        <div className="row align-items-center">
                            
                            {/* Resumen propiedad */}
                            <div className="col-lg-5 ">
                                <h2 className="text-body-emphasis">Oriente antioqueño</h2>
                                <p className="fs-5 col-md-8">Inversión con respaldo en la nueva era digital.</p>
                                <hr className="col-3 col-md-2 mb-5"></hr>

                                <h3 className="text-body-emphasis">San vicente - el Peñol</h3>
                                <p>Ready to go beyond the starter template? Check out these open source projects that you can quickly duplicate to a new GitHub repository.</p>
                                <ul className="list-unstyled ps-0">
                                    <li><i className="bi bi-check-square"></i> Solo 18 lotes desde 1.800 mt²</li>
                                    <li><i className="bi bi-check-square-fill"></i> A tan solo hora y media de Medellín y 20 minutos de El Peñol, Antioquia</li>
                                    <li><i className="bi bi-check-square"></i> La valorización esperada es del 50% en dos años</li>
                                    <li><i className="bi bi-check-square-fill"></i> Construcción y arquitectura responsable</li>
                                </ul>
                            </div>


                            {/* Carrusel */}
                            <div className="col-lg-7">
                                <div id="carouselCompra" className="carousel slide contCarruselComprarInfo" >
                                    <div className="carousel-inner">
                                        <div className="carousel-item active"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+7.jpg" className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                        <div className="carousel-item"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+5.jpg"        className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                        <div className="carousel-item"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+6.jpg"        className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                        <div className="carousel-item"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+4.jpg"        className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                        <div className="carousel-item"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+8.jpg"        className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                        <div className="carousel-item"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+9.jpg"        className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                        <div className="carousel-item"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+10.jpg"       className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselCompra" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselCompra" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* <button onClick={crearPagosDB}>CREAR PAGOS EN DB</button> */}



                    {/* NFTS DISPONIBLES */}
                    <div id="contenedorNFTS" className="p-5 mb-4 bg-body-tertiary rounded-3">
                        <h2>NFTS disponibles</h2>
                        <p>Aquí podrás ver todos los nfts disponibles. Podrás comprar tanto los que son propiedad de Native como los que son de otros usuarios.<br /> 
                            <i>Si deseas un nft de otro usuario, debes hacer click en enviar solicitud para poder acordar la compra.</i> 
                        </p>
                        {/* El total de columnas es: largo del arreglo / 4 */}
                        <div className="row">
                            
                            {estado.arregloTokensPropiedades.map((propiedadActual, index) => (
                                
                                <div className="col-md-2" key={index} > 
                                    <div className="card m-1">
                                        <div className="card-header text-light" style={{backgroundColor: "#414525"}} >{propiedadActual.id}</div>
                                        <img src={propiedadActual.imagenNFT} alt="Imagen desde IPFS" style={{width: "100px"}} className="card-img-top" />
                                        

                                        <div className="card-body">
                                            <h5 className="card-title">{propiedadActual.nombrePropiedad}</h5>
                                            <p className="card-text">{Web3.utils.fromWei(propiedadActual.precio.toString(), 'Ether')} Eth <br />
                                                <strong>Propietario:</strong> {propiedadActual.usuarioPropietario}
                                            </p>

                                               
                                            <button
                                            className="w-100 btn btn-primary"
                                            id={propiedadActual.id}
                                            name={propiedadActual.usuarioPropietario}
                                            value={propiedadActual.precio}
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                id: event.target.id,
                                                usuarioPropietario: event.target.name,
                                                precio: event.target.value,
                                                direccionPropietario: propiedadActual.direccionPropietario, // enviamos más datos dentro de button
                                                };
                                                funcionComprar(datosAdicionales);
                                            }}
                                            >
                                            Comprar NFT
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>         
                </div>
            </div>
        </div>
    )
}



export default Comprar;

