import '../../estilos/paginas.css';
import React, {useState, useEffect} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Web3 from 'web3';
import ModalRegistrarIniciar from './mondalRegistrarIniciar';
var web3 





function Unidad15() {


  // Capturamos el valor de params
  const routeParams = useParams();
  //console.log(routeParams);
  // Capitalizar y eliminar guiones de nombre de propiedad
  const nombrePropiedad = "Unidad 15";


  // useState blockchain
  const [estado, setEstado] = useState({
    account: "0x960129703adE9373A5ae201424FFFED959698AAc",
    cuentaComprador: "",
    cantidadTokens: 0,
    arregloTokensPropiedades: [],
    loading: true,
    abiContrato: "",
    addressContrato: "",
    contratoEnBlockchain: "",
    precioPorToken: 0,
  });
  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {

    // Obtener cuentas para seleccionar cuál se usará
    async function loadBlockchainData() {
      //const conexionWeb3 = new Web3(window.ethereum); // conectamos y almacenamos conexión a web3
      const conexionWeb3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_INFURAMAINNET));


      //Cargar contrato en la blockchain desde "servidor/blockchain"
      await axios.post("/obtener-contrato")
      .then(async function(response){
          // Actualizamos los valores de abi y adress en el estado, pro si los necesitamos.
          setEstado(prevEstado => { return {...prevEstado, abiContrato: response.data.abi, addressContrato: response.data.address, } });
          // Creamos variables ABI y ADDRESS dinámicas
          const contratoMarketplace = response.data.contrato; // obtenemos el contrato
          const networkId   = await conexionWeb3.eth.net.getId(); // obtenemos el id para determinar a que red estamos conectados.
          const networkData = contratoMarketplace.networks[networkId]; // buscamos la red con base en el id anterior.

          if(networkData){
              const marketplaceEnBlockchain = new conexionWeb3.eth.Contract(contratoMarketplace.abi, networkData.address); // Agregamos el contrato a la blockchain. - agregamos gas
              setEstado(prevEstado => { return { ...prevEstado, contratoEnBlockchain: marketplaceEnBlockchain, loading: false } }); // guardamos el contrato que ya está en la blocjchain, en el estado, junto con loading. 
              let cantidadTokens = Number(await marketplaceEnBlockchain.methods.cantidadTokens().call()); // ejecutamos la cantidad de tokens
              setEstado(prevEstado => { return { ...prevEstado, cantidadTokens: cantidadTokens } }); // guardamos la cantidad de tokens en el estado
              
              //console.log("Cantidad de tokens: ", cantidadTokens);
              //console.log("arreglo propuedades: ", estado.arregloTokensPropiedades, " y cantidad de tokens: ", cantidadTokens);


              // REVISAMOS ARREGLO PARA MOSTRAR PROPIEDADES CREADAS
              // lo hago desde 1, porque desde 0, la posición 0 no tiene nada, es un nft como boceto y no se puede comprar ni usar.
              for(var i=1; i<=cantidadTokens; i++){
                  let propiedadActual = await marketplaceEnBlockchain.methods.tokensPropiedades(i).call(); // cargamos la propiedad que esta´iterando.
                  setEstado(prevEstado => { return { ...prevEstado, arregloTokensPropiedades: [...prevEstado.arregloTokensPropiedades, propiedadActual] } }); // prevESTADO se usa tanto para el estado completo, como para el arrelgo interno, y de esa manera, actualizamos en la ulitma pos y mantenimos elr esto.
                  // Guardamos el precio aproximado para mostrar
                  if(i===2){
                      setEstado(prevEstado => { return { ...prevEstado, precioPorToken: propiedadActual.precio } });
                  }
              }

              // Encontrar el valor
              
                  
              
              
          }
          else{ window.alert('El contrato de Marketplace no se implementó en la red detectada.'); }
      });


    }

    loadBlockchainData();






    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////
   
  



  return(
      <div className='contenedorPaginasUsuario'>
          <div className='contenedorSidebarYcontenido d-flex flex-row '>


              {/* Columna 2 */}
              <div className="flex-grow-1 container-fluid">
                  
                  {/* Contenedor título  */}
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                      <h1 className="h2">Comprar NFTS</h1>
                  </div>
          

                    {/* Contenedor resumen propiedad */}
                    <div className="p-5 mb-4 rounded-3" style={{backgroundColor: "#223531"}}>
                        <div className="row">
                            {/* Imagen */}
                            <div className="col-lg-6">
                                    <div className="d-flex justify-content-center"> <img className="imagenPropiedadComprar" src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+1.jpg"  alt="" /></div>
                            </div>

                            {/* Resumen propiedad */}
                            <div className="col-lg-6">
                                <div className="container-fluid py-5">
                                    
                                    <div className="card mb-4 rounded-3 shadow-sm">
                                        <div className="card-header py-3" style={{backgroundColor: "#414525"}} >
                                            <h2 className="my-0 fw-normal text-light">{nombrePropiedad}</h2>
                                        </div>
                                        <div className="card-body">
                                            <h1 className="card-title pricing-card-title"><i className="fa-brands fa-ethereum" style={{fontSize:"30px"}} ></i> {Web3.utils.fromWei(estado.precioPorToken.toString(), 'Ether')} <small className="text-body-secondary fw-light">eth / unidad</small></h1>
                                            <ul className="list-unstyled mt-3 mb-4">
                                                <li><strong> Aproximado USD:</strong>  $1.000 / unidad</li>
                                                <li><strong>Cantidad de nfts:</strong>  {estado.cantidadTokens}</li>
                                                <li><strong>Rentabilidad:</strong> 12% AL 15% efectivo anual.</li>
                                                <li><strong>Devolución de capital:</strong> Mes 43 al propietario actual del NFT. Se paga utilidad representada en USD.</li>
                                                <li><strong>Valorización:</strong> 21%. Se paga 7% anual.</li>
                                                <li><strong>Sin cláusulas de permanencia.</strong> Compra y vende cuando desee.</li>
                                            </ul>
                                            
                                            <a className="w-100 btn btn-lg btn-primary" href="#contenedorNFTS" role="button">Ver NFTS disponibles</a>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    {/* Carrusel de fotos */}
                    <div id="fotosPropiedad" className="carousel slide mb-6" data-bs-ride="carousel" >
                        {/* Indicadores */}
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#fotosPropiedad" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#fotosPropiedad" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#fotosPropiedad" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>


                        <div className="carousel-inner" >
                            {/* Descripción 1 */}
                            <div className="carousel-item active" >
                                {/* imagen 1 */}
                                <div class="d-flex align-items-center justify-content-center h-100">
                                    <img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+2.png" className="imagenCarrusel d-block w-100" alt="..." />
                                </div>
                                <div className="container textoCarrusel">
                                    <div className="carousel-caption text-start">
                                        <h1>Ubicación.</h1>
                                        <p className="opacity-75">A tan solo hora y media de Medellín y 20 minutos de El Peñol, Antioquia.</p>
                                    </div>
                                </div>
                            </div>
                            {/* Propiedad 2 */}
                            <div className="carousel-item">
                            {/* imagen 2 */}
                                <div class="d-flex align-items-center justify-content-center h-100">
                                <img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+3.png" className="imagenCarrusel d-block w-100" alt="..." />
                                </div>
                                <div className="container">
                                <div className="carousel-caption">
                                    <h1>Oportunidad de negocio.</h1>
                                    <p>La valorización esperada es del 50% en dos años.</p>
                                </div>
                                </div>
                            </div>
                            {/* Propiedad 3 */}
                            <div className="carousel-item">
                            {/* imagen 3 */}
                                <div class="d-flex align-items-center justify-content-center h-100">
                                    <img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+2.png" className="imagenCarrusel d-block w-100" alt="..." />
                                </div>
                                <div className="container">
                                <div className="carousel-caption text-end">
                                    <h1>Medio ambiente.</h1>
                                    <p>Construcción y arquitectura responsable.</p>
                                </div>
                                </div>
                            </div>
                        </div>

                        {/* Botones */}
                        <button className="carousel-control-prev" type="button" data-bs-target="#fotosPropiedad" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#fotosPropiedad" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                
                  
                    {/* Contendor video y tour 360 */}
                    <div className='container'>

                        {/* video */}
                        <div className='container text-center d-flex align-items-center justify-content-center'>
                            <div className="container-fluid py-1">
                                <h2 className="display-5 fw-bold">¿Por qué invertir en native?</h2>
                                <p className=" mb-5">Conoce aquí algunas razones para invertir en Native.</p>
                                <iframe className='claseIframeVideos' src="https://www.youtube.com/embed/MVyw2-5AK5Q?si=VGi403v-9RoW0fif" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>

                        <hr className="featurette-divider" />

                        {/* tour 360 */}
                        <div className='container text-center'>
                            <div className="container-fluid py-1">
                                <h2 className="display-5 fw-bold">Tour 360</h2>
                                <p className=" mb-5">Aquí puedes ver nuestro tour 360 para propiedades en san vicente, incluyendo la Unidad 15..</p>
                                <img  className='tour360sanvicente mb-5' src="https://bucket-web-general.s3.us-east-2.amazonaws.com/tour+360+San+vicente.png" alt=''></img>
                                <a class="btn py-2 btn-secondary w-100" href="https://kuula.co/share/collection/7F3KR?logo=0&info=0&fs=1&vr=1&autorotate=0.08&thumbs=-1&inst=es" target='_blank' rel="noreferrer" role="button">Ver Tour 360</a>
                            </div>  
                        </div>

                        <hr className="featurette-divider" />
                    </div>


    




                    {/* Contenedor información propiedad */}
                    <div className="py-5 px-5 mb-5 text-light rounded-3" style={{backgroundColor: "#223531"}}> 
                        <div className="row align-items-center">
                            
                            {/* Resumen propiedad */}
                            <div className="col-lg-5 ">
                                <h2 className="">Oriente antioqueño</h2>
                                <p className="fs-5 col-md-8">Inversión con respaldo en la nueva era digital.</p>
                                <hr className="col-3 col-md-2 mb-5"></hr>

                                <h3 className="">San vicente - el Peñol</h3>
                                <p>Ubicación, precio y rentabilidad.</p>
                                <ul className="list-unstyled ps-0 mt-5">
                                    <li><i className="bi bi-check-square"></i> Solo 18 lotes desde 1.800 mt²</li>
                                    <li><i className="bi bi-check-square-fill"></i> A tan solo hora y media de Medellín y 20 minutos de El Peñol, Antioquia</li>
                                    <li><i className="bi bi-check-square"></i> La valorización esperada es del 50% en dos años</li>
                                    <li><i className="bi bi-check-square-fill"></i> Construcción y arquitectura responsable</li>
                                </ul>
                            </div>


                            {/* Carrusel */}
                            <div className="col-lg-7">
                                <div id="carouselCompra" className="carousel slide contCarruselComprarInfo" >
                                    <div className="carousel-inner">
                                        <div className="carousel-item active"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+7.jpg" className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                        <div className="carousel-item"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+8.jpg"        className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                        <div className="carousel-item"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+9.jpg"        className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                        <div className="carousel-item"><img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+10.jpg"       className="d-block w-100 rounded" alt="unidad 15 native" /></div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselCompra" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselCompra" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>





                    {/* Contenedor tipologías */}
                    <div id="contenedorTipologias" className="carousel slide" data-bs-ride="carousel">
                        {/* Indicadores */}
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#contenedorTipologias" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#contenedorTipologias" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#contenedorTipologias" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>

                        {/* Tipologías */}
                        <div className="carousel-inner" >
                            {/* Tipología 83mts2 */}
                            <div className="carousel-item active" >
                               <div className='row'>
                                    {/* Imagen */}
                                    <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                                        <img src='https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+6.jpg' alt="tipología 1" className='imagenesTipologias' />
                                    </div>
                                    {/* Texto */}
                                    <div className='col-lg-6 d-flex align-items-center justify-content-center py-3'>
                                        <div>
                                            <h3>Tipología de 83 mts2</h3>
                                            <ul>
                                                <li>2 Habitaciones</li>
                                                <li>2 Baños</li>
                                                <li>2 Balcones</li>
                                                <li>1 Sala principal</li>
                                                <li>1 Cocina</li>
                                                <li>Zona de Ropas</li>
                                                <li>Parqueadero privado</li>
                                                <li>Diseño de construcción con estilo Steel Framing de bajo impacto ambiental</li>
                                            </ul>
                                        </div>
                                    </div>
                               </div>
                            </div>
                            {/* Tipología 100mts2 */}
                            <div className="carousel-item" >
                               <div className='row'>
                                    {/* Imagen */}
                                    <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                                        <img src='https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+5.jpg' alt="tipología 1" className='imagenesTipologias' />
                                    </div>
                                    {/* Texto */}
                                    <div className='col-lg-6 d-flex align-items-center justify-content-center py-3'>
                                        <div>
                                            <h3>Tipología de 100 mts2</h3>
                                            <ul>
                                                <li>2 Habitaciones</li>
                                                <li>2 Baños</li>
                                                <li>2 Balcones</li>
                                                <li>1 Sala principal</li>
                                                <li>1 Cocina</li>
                                                <li>Zona de Ropas</li>
                                                <li>Parqueadero privado</li>
                                                <li>Diseño de construcción con estilo Steel Framing de bajo impacto ambiental</li>
                                            </ul>
                                        </div>
                                    </div>
                               </div>
                            </div>
                            {/* Tipología 114mts2 */}
                            <div className="carousel-item" >
                               <div className='row'>
                                    {/* Imagen */}
                                    <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                                        <img src='https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+4.jpg' alt="tipología 1" className='imagenesTipologias' />
                                    </div>
                                    {/* Texto */}
                                    <div className='col-lg-6 d-flex align-items-center justify-content-center py-3'>
                                        <div>
                                            <h3>Tipología de 114 mts2</h3>
                                            <ul>
                                                <li>3 Habitaciones</li>
                                                <li>2 Baños</li>
                                                <li>2 Balcones</li>
                                                <li>1 Sala principal</li>
                                                <li>1 Cocina</li>
                                                <li>Zona de Ropas</li>
                                                <li>Parqueadero privado</li>
                                                <li>Diseño de construcción con estilo Steel Framing de bajo impacto ambiental</li>
                                            </ul>
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </div>

                        {/* Botones */}
                        <button className="carousel-control-prev" type="button" data-bs-target="#contenedorTipologias" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#contenedorTipologias" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>







                    {/* NFTS DISPONIBLES */}
                    <div id="contenedorNFTS" className="p-5 mb-4 rounded-3" style={{backgroundColor: "#223531"}}>
                        <h2 className='text-light'>NFTS disponibles</h2>
                        <p className='text-light'>Aquí podrás ver todos los nfts disponibles. Podrás comprar tanto los que son propiedad de Native como los que son de otros usuarios.<br /> 
                            <i>Si deseas un nft de otro usuario, debes hacer click en enviar solicitud para poder acordar la compra.</i> 
                        </p>
                        {/* El total de columnas es: largo del arreglo / 4 */}
                        <div className="row">
                            
                            {estado.arregloTokensPropiedades.map((propiedadActual, index) => (
                                
                                <div className="col-sm-4 col-lg-2" key={index} > 
                                    <div className="card m-1">
                                        <div className="card-header text-light" style={{backgroundColor: "#414525"}} >{propiedadActual.id}</div>
                                        <img src={propiedadActual.imagenNFT} alt="Imagen desde IPFS" style={{width: "100px"}} className="card-img-top" />
                                        

                                        <div className="card-body">
                                            <h5 className="card-title">{propiedadActual.nombrePropiedad}</h5>
                                            <p className="card-text">{Web3.utils.fromWei(propiedadActual.precio.toString(), 'Ether')} Eth <br />
                                                <strong>Propietario:</strong> {propiedadActual.usuarioPropietario}
                                            </p>

                                            <ModalRegistrarIniciar />                                               
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>         
              </div>
          </div>
      </div>
  )
}

export default Unidad15;