function FooterGlobal(){
    return(
        <div className="contenedorFooterGlobal">
            <div className="container">
                <footer className="pt-5">
                    {/* Primera parte */}
                    <div className="row">
                        {/* Consumidor */}
                        <div className="col-6 col-md-2 mb-3">
                            <h5 className="text-light">Consumidor</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="/privacidad" className="nav-link p-0" >Política de privavidad</a></li>
                                <li className="nav-item mb-2"><a href="/terminos" className="nav-link p-0" >Términos y condiciones</a></li>
                                <li className="nav-item mb-2"><a href="/cookies" className="nav-link p-0" >Política de cookies</a></li>
                            </ul>
                        </div>
                        {/* Native */}
                        <div className="col-6 col-md-2 mb-3">
                            <h5 className="text-light">Native</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="/unidad-15" className="nav-link p-0 text-body-secondary">Unidad 15</a></li>
                                <li className="nav-item mb-2"><a href="/contacto" className="nav-link p-0 text-body-secondary">Contacto</a></li>
                                <li className="nav-item mb-2"><a href="/como-funciona" className="nav-link p-0 text-body-secondary">Cómo funciona</a></li>
                            </ul>
                        </div>
                        {/* Usuario */}
                        <div className="col-6 col-md-2 mb-3">
                            <h5 className="text-light">Usuario</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="/iniciar-sesion" className="nav-link p-0 text-body-secondary">Iniciar Sesión</a></li>
                                <li className="nav-item mb-2"><a href="/registrar" className="nav-link p-0 text-body-secondary">Registrar</a></li>
                            </ul>
                        </div>      
                        {/* Contáctanos */}
                        <div className="col-md-5 offset-md-1 mb-3">
                            <h5 className="text-light">Contáctanos</h5>
                            
                            {/* Email */}
                            <div className="d-flex flex-row">
                                <div className="pe-2"><i className="bi bi-envelope-at-fill"></i></div>
                                <div className="pe-2"><p>contacto@native.com.co</p></div>
                            </div>
                            {/* Teléfono */}
                            <div className="d-flex flex-row">
                                <div className="pe-2"><i className="bi bi-telephone-outbound-fill"></i></div>
                                <div className="pe-2"><p>57 316 166 5347</p></div>
                            </div>
                            {/* Dirección */}
                            <div className="d-flex flex-row">
                                <div className="pe-2"><i className="bi bi-geo-alt-fill"></i></div>
                                <div className="pe-2"><p>Transversal 39 A # 70 A - 16, Laureles Medellín, Antioquia</p></div>
                            </div>
                        </div>
                    </div>

                    {/* Segunda parte  */}
                    <div className="row border-top pt-4 mt-4 pb-3">
                        <div className="copyright col-lg-5">
                            <p>Copyright © 2023, Native. <a style={{textDecoration:"none"}} href="/iniciar-sesion-administrador"> Admin</a> </p>
                        </div>

                        <div className="col-lg-2" style={{textAlign: "center"}}>
                            <p>Hecho por <a style={{textDecoration:"none"}} href="https://pretwor.com/" target="_blank" rel="noreferrer">Pretwor</a></p>
                        </div>

                        <div className="col-lg-5">
                            <div className="d-flex align-items-start justify-content-center justify-content-lg-end">
                                <a className="link-body-emphasis px-2" href="https://www.facebook.com/profile.php?id=100088168931463 " target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></a>
                                <a className="link-body-emphasis px-2" href="https://wa.me/573161665347 " target="_blank"  rel="noreferrer"><i className="bi bi-whatsapp"></i></a>
                                <a className="link-body-emphasis px-2" href="https://www.instagram.com/nativeoriente " target="_blank"  rel="noreferrer"><i className="bi bi-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default FooterGlobal;