import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Web3 from 'web3';
// Replace with the URL of your Ganache instance
var web3 

function Pagos(){
    const navigate = useNavigate();
    const routeParams = useParams();
    const urlVolver   = "/admin/pagos/" + routeParams.username;

    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    const [pagosDelMes, setPagosDelMes] = useState([]); // guarda todos los pagos del mes
    const [pagosDelDia, setPagosDelDia] = useState([]); // pagos del día actual
    const [pagosAnteriores, setPagosAnteriores] = useState([]); // pagos del día actual
    const [pagosMesesAntes, setPagosMesesAntes] = useState([]);
    const [totalDia, setTotalDia] = useState("");
    const [totalAnteriores, setTotalAnteriores] = useState("");
    const [totalMesesAnteriores, setTotalMesesAnteriores] = useState("");
    const [cuenta, setCuenta] = useState("");


    let urlEtherscan = "https://etherscan.io/";
    let urlSepolia   = "https://sepolia.etherscan.io/";
    let urlAusar     = urlEtherscan; // cambiar a producción

    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////


        // Pagos del mes
        async function obtenerPagos(){
            await axios.post("/obtener-pagos")
            .then(async function(response){

                // GUARDAMOS los respectivos pagos en sus variables
                setPagosDelMes(response.data.pagosMes);
                setPagosDelDia(response.data.pagosDia);
                setPagosAnteriores(response.data.pagosAnteriores);
                setPagosMesesAntes(response.data.pagosPendientesMesesAnteriores);

                const sumaValoresAnteriores = response.data.pagosAnteriores.reduce((total, pago) => {
                    return total + pago.pagoPediente[0].valor;
                }, 0);
                setTotalAnteriores(sumaValoresAnteriores);

                const sumaValoresDia = response.data.pagosDia.reduce((total, pago) => {
                    return total + pago.pagoPediente[0].valor;
                }, 0);
                setTotalDia(sumaValoresDia);
                
                const sumaValoresMesesAnteriores = response.data.pagosPendientesMesesAnteriores.reduce((total, pago) => {
                    return total + pago.pagoPediente[0].valor;
                }, 0);
                setTotalMesesAnteriores(sumaValoresMesesAnteriores);
            });
        }
        obtenerPagos();


        // // función para conectar con metamask: abre ventana extensión para conectar
        async function loadWeb3(){
            window.addEventListener('load', async () => {
            // FORMA1: Modern dapp browsers... - chrome
            if (window.ethereum) {
                // crea una nueva conexión a la blockchain. Se pasa como argumento window.ethereum. Luego lo hbilitamos
                try {
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    setCuenta(accounts[0]);  // Actualizamos account con el valor obtenido, PERO mantenimiento los otros valores anteriores.
                } catch (error) {
                    if (error.code === 4001) {
                        // User rejected request
                        console.log("error 4001");
                    }
                }
            }
        
            // FORMA2: Legacy dapp browsers...
            else if (window.web3) { window.web3 = new Web3(web3.currentProvider); }
        
            // FORMA3: Non-dapp browsers...
            else {console.log('No se detectó un navegador que no es Ethereum. ¡Deberías considerar probar MetaMask!');}
            });
        }

        loadWeb3();



        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


    
   
    

    async function funcionPagar(datos){
        // datos puede ser: dia o anteriores
        let pagoUsar = [];

        //1. asignar el arreglo a usar.
        switch (datos) {
            case "dia":             pagoUsar=pagosDelDia;     break;
            case "anteriores":      pagoUsar=pagosAnteriores; break;
            case "mesesAnteriores": pagoUsar=pagosMesesAntes; break;
        }

        //2. Obtener arrelo con precios en ETH.
        await axios.post("/obtener-direcciones-valores-eth", pagoUsar)
        .then(async function(response){

            let arregloPagos   = response.data.arregloPagos; // arreglo qeu contiene todos los pagos a pagar.
            const conexionWeb3 = new Web3(window.ethereum); // conectamos y almacenamos conexión a web3


            try {
                // Creamos arreglo de promesas. Cada una representa una transacción.
                const txs = arregloPagos.map(infoActual => {
                    // Creamos info de la transacción
                    const tx = {
                        from: cuenta,
                        to: infoActual.direccion,
                        value: Web3.utils.toWei(infoActual.valorETH, "ether"),
                    };
                    // Ejecutamos el envío de la transacción.
                    return conexionWeb3.eth.sendTransaction(tx);
                });


                // Espera a que todas las transacciones se completen y captura los resultados de todas las transacciones
                const txReceipts = await Promise.all(txs);
                // Obtén los hashes de las transacciones y guardamos en el arreglo txHASHES. pos 0 guarda hash de la primera transacción, y así sucesivamente. 
                const txHashes = txReceipts.map(receipt => receipt.transactionHash);
                // Unir los dos arreglos, añadir el hash a cada transacción.
                const pagosConHashes = arregloPagos.map((pago, index) => ({
                    ...pago,
                    hash: txHashes[index],
                }));

                // ACTUALIZAR PAGOS EN DB //////////////////////////////////////////////////
                await axios.post("/actualizar-pagos", pagosConHashes)
                .then(function(response){
                    if(response.status===200){
                        return navigate("/alerta", {state: {titulo:"Pagos realizados correctamente", mensaje:"La transacción se ha ejecutado correctmente. Por favor espera unos minutos a que esta se confirme y luego podrás verificar en tus propiedades.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
                    }
                });
            }
            catch (error) { console.error("Error al realizar los pagos:", error); }
        });
    };


    


    

    return(
        <div className='contenedorPaginasUsuario'>
            


            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                        <h1 className="h2 text-light">Pagos</h1>
                    </div>




                    <div className="contenedorUno p-5 mb-4 rounded-3">
                        <div className="container-fluid py-5">
                            <h1 className="display-5 fw-bold text-light">Pagos este mes</h1>
                            <p className="col-md-8 fs-4 text-light">Aquí podrás ver todos los pagos pendientes de este mes. Están divididos en los pagos del día actual, pagos pasados que aún no se han realizado, y todos los pagos siguientes, del mismo mes.</p>

                            

                            {/* pagos del mismo día */}
                            <h2 className="text-light mt-5">Día actual</h2>
                            <p className="text-light">Estos son todos los pagos pendientes del día actual.</p>
                            {/* Borde */}
                            <div className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >
                                { (pagosDelDia.length>0)
                                    ?  <div> 
                                        {pagosDelDia.map( (pagoActual, key) => (
                                        <div key={pagoActual.nftInfo._id} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                            

                                            {/* Avatar */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <img src={pagoActual.nftInfo.imagenNFT} alt="avatar" style={{width: "80px", borderRadius: "50%"}} />
                                                </div>
                                            </div>

                                            {/* Valor a pagar */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$ " + pagoActual.pagoPediente[0].valor  }  />
                                                    <label htmlFor="floatingInputGrid">Valor a Pagar</label>
                                                </div>
                                            </div>

                                            {/* Fecha de pogo */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.pagoPediente[0].fecha.dia + "/" + pagoActual.pagoPediente[0].fecha.mes + "/" + pagoActual.pagoPediente[0].fecha.año }  />
                                                    <label htmlFor="floatingInputGrid">Fecha de pago</label>
                                                </div>
                                            </div>

                                            {/* id */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.idNFT}  />
                                                    <label htmlFor="floatingInputGrid">Id NFT</label>
                                                </div>
                                            </div>

                                            {/* usuario Propietario */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.usuarioPropietario}  />
                                                    <label htmlFor="floatingInputGrid">Usuario Propietario</label>
                                                </div>
                                            </div>

                                            {/* Nombre Propiedad */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.nombrePropiedad}  />
                                                    <label htmlFor="floatingInputGrid">Propiedad</label>
                                                </div>
                                            </div>

                                            {/* Fecha de compra */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.dia + "/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.mes + "/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.año }  />
                                                    <label htmlFor="floatingInputGrid">Fecha Compra</label>
                                                </div>
                                            </div>

                                            {/* Precio */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={Web3.utils.fromWei(pagoActual.nftInfo.precio.toString(), 'Ether') + " ETH"}   />
                                                    <label htmlFor="floatingInputGrid">Precio</label>
                                                </div>
                                            </div>


                                            {/* Tarifa ETH en USD */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue= {"$" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].valor}  />
                                                    <label htmlFor="floatingInputGrid">Tarifa ETH</label>
                                                </div>
                                            </div>

                                            {/* Costo NFT en USD */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$" + (pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].valor * Number(Web3.utils.fromWei(pagoActual.nftInfo.precio.toString(), 'Ether')) ).toFixed()  }  />
                                                    <label htmlFor="floatingInputGrid">NFT en USD</label>
                                                </div>
                                            </div>

                                            {/* Hash transacción */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].hash.substring(0, 10) + "..."} />
                                                    <label htmlFor="floatingInputGrid">Hash transacción</label>
                                                </div>
                                            </div>


                                            


                                            {/* Botón ver transacción */}
                                            <div className="col-sm-1">
                                                <a className="btn btn-primary btn-lg w-100" target="_blank" rel="noreferrer" href={ urlAusar + "tx/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].hash } role="button">Ver hash</a>
                                            </div>



                                        </div>
                                        ))
                                        }
                                        
                                        <h4 style={{textAlign: "center"}} className="text-light mt-5">DEBE TENER EN LA CUENTA MÁS DE ${totalDia} USD PARA ASEGURAR TODOS LOS PAGOS</h4>

                                        {/* Botón */}
                                        <div className="col-sm-1 w-100">
                                            <button
                                            className="w-100 btn btn-lg btn-primary"
                                            onClick={(event) => { funcionPagar("dia");}}
                                            >
                                            Pagar pagos día actual
                                            </button>
                                        </div>
                                    </div>
                                    : <p className="text-light">Aún cargando o no hay pagos pendientes.</p>
                                }
                            </div>  



                            {/* pagos ANTERIORES al día actual */}
                            <h2 className="text-light mt-5">Anterioes al día actual</h2>
                            <p className="text-light">Estos son todos los pagos pendientes anteriores al día actual</p>
                            {/* Borde */}
                            <div className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >
                                { (pagosAnteriores.length>0)
                                    ? <div>
                                        {pagosAnteriores.map( (pagoActual, key) => (
                                        <div key={pagoActual.nftInfo._id} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                            

                                            {/* Avatar */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <img src={pagoActual.nftInfo.imagenNFT} alt="avatar" style={{width: "80px", borderRadius: "50%"}} />
                                                </div>
                                            </div>

                                            {/* Valor a pagar */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$ " + pagoActual.pagoPediente[0].valor  }  />
                                                    <label htmlFor="floatingInputGrid">Valor a Pagar</label>
                                                </div>
                                            </div>

                                            {/* Fecha de pogo */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.pagoPediente[0].fecha.dia + "/" + pagoActual.pagoPediente[0].fecha.mes + "/" + pagoActual.pagoPediente[0].fecha.año }  />
                                                    <label htmlFor="floatingInputGrid">Fecha de pago</label>
                                                </div>
                                            </div>

                                            {/* id */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.idNFT}  />
                                                    <label htmlFor="floatingInputGrid">Id NFT</label>
                                                </div>
                                            </div>

                                            {/* usuario Propietario */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.usuarioPropietario}  />
                                                    <label htmlFor="floatingInputGrid">Usuario Propietario</label>
                                                </div>
                                            </div>

                                            {/* Nombre Propiedad */}
                                            <div className="col-sm-3">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.nombrePropiedad}  />
                                                    <label htmlFor="floatingInputGrid">Propiedad</label>
                                                </div>
                                            </div>

                                            {/* Fecha de compra */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.dia + "/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.mes + "/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.año }  />
                                                    <label htmlFor="floatingInputGrid">Fecha Compra</label>
                                                </div>
                                            </div>

                                            {/* Precio */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={Web3.utils.fromWei(pagoActual.nftInfo.precio.toString(), 'Ether') + " ETH"}   />
                                                    <label htmlFor="floatingInputGrid">Precio</label>
                                                </div>
                                            </div>


                                            {/* Tarifa ETH en USD */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue= {"$" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].valor}  />
                                                    <label htmlFor="floatingInputGrid">Tarifa ETH</label>
                                                </div>
                                            </div>

                                            {/* Costo NFT en USD */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$" + (pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].valor * Number(Web3.utils.fromWei(pagoActual.nftInfo.precio.toString(), 'Ether')) ).toFixed()  }  />
                                                    <label htmlFor="floatingInputGrid">NFT en USD</label>
                                                </div>
                                            </div>

                                            {/* Hash transacción */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].hash.substring(0, 10) + "..."} />
                                                    <label htmlFor="floatingInputGrid">Hash transacción</label>
                                                </div>
                                            </div>


                                            


                                            {/* Botón ver transacción */}
                                            <div className="col-sm-2">
                                                <a className="btn btn-primary btn-lg w-100" target="_blank" rel="noreferrer" href={ urlAusar + "tx/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].hash } role="button">Ver hash</a>
                                            </div>



                                        </div>
                                        ))}

                                        <h4 style={{textAlign: "center"}} className="text-light mt-5">DEBE TENER EN LA CUENTA MÁS DE ${totalAnteriores} USD PARA ASEGURAR TODOS LOS PAGOS</h4>

                                       {/* Botón */}
                                       <div className="col-sm-1 w-100">
                                            <button
                                            className="w-100 btn btn-lg btn-primary"
                                            onClick={(event) => { funcionPagar("anteriores");}}
                                            >
                                            Pagar pagos anteriores
                                            </button>
                                        </div>
                                    </div>
                                    : <p className="text-light">Aún cargando o no hay pagos pendientes.</p>
                                }
                            </div>  





                            {/* todos los pagos del mes */}
                            <h2 className="text-light mt-5">Todos los pagos del mes</h2>
                            <p className="text-light">Estos son todos los pagos pendientes del mes.</p>
                            {/* Borde */}
                            <div className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >
                                { (pagosDelMes.length>0)
                                    ? pagosDelMes.map( (pagoActual, key) => (
                                        <div key={pagoActual.nftInfo._id} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                            

                                            {/* Avatar */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <img src={pagoActual.nftInfo.imagenNFT} alt="avatar" style={{width: "80px", borderRadius: "50%"}} />
                                                </div>
                                            </div>

                                            {/* Valor a pagar */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$ " + pagoActual.pagoPediente[0].valor  }  />
                                                    <label htmlFor="floatingInputGrid">Valor a Pagar</label>
                                                </div>
                                            </div>

                                            {/* Fecha de pogo */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.pagoPediente[0].fecha.dia + "/" + pagoActual.pagoPediente[0].fecha.mes + "/" + pagoActual.pagoPediente[0].fecha.año }  />
                                                    <label htmlFor="floatingInputGrid">Fecha de pago</label>
                                                </div>
                                            </div>

                                            {/* id */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.idNFT}  />
                                                    <label htmlFor="floatingInputGrid">Id NFT</label>
                                                </div>
                                            </div>

                                            {/* usuario Propietario */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.usuarioPropietario}  />
                                                    <label htmlFor="floatingInputGrid">Usuario Propietario</label>
                                                </div>
                                            </div>

                                            {/* Nombre Propiedad */}
                                            <div className="col-sm-3">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.nombrePropiedad}  />
                                                    <label htmlFor="floatingInputGrid">Propiedad</label>
                                                </div>
                                            </div>

                                            {/* Fecha de compra */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.dia + "/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.mes + "/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.año }  />
                                                    <label htmlFor="floatingInputGrid">Fecha Compra</label>
                                                </div>
                                            </div>

                                            {/* Precio */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={Web3.utils.fromWei(pagoActual.nftInfo.precio.toString(), 'Ether') + " ETH"}   />
                                                    <label htmlFor="floatingInputGrid">Precio</label>
                                                </div>
                                            </div>


                                            {/* Tarifa ETH en USD */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue= {"$" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].valor}  />
                                                    <label htmlFor="floatingInputGrid">Tarifa ETH</label>
                                                </div>
                                            </div>

                                            {/* Costo NFT en USD */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$" + (pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].valor * Number(Web3.utils.fromWei(pagoActual.nftInfo.precio.toString(), 'Ether')) ).toFixed()  }  />
                                                    <label htmlFor="floatingInputGrid">NFT en USD</label>
                                                </div>
                                            </div>

                                            {/* Hash transacción */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].hash.substring(0, 10) + "..."} />
                                                    <label htmlFor="floatingInputGrid">Hash transacción</label>
                                                </div>
                                            </div>


                                            


                                            {/* Botón ver transacción */}
                                            <div className="col-sm-2">
                                                <a className="btn btn-primary btn-lg w-100" target="_blank" rel="noreferrer" href={ urlAusar + "tx/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].hash } role="button">Ver hash</a>
                                            </div>



                                        </div>
                                    ))
                                    : <p className="text-light">Aún cargando o no hay pagos pendientes.</p>
                                }
                            </div>    



                            {/* todos los pagos anteriores */}
                            <h2 className="text-light mt-5">Todos los de meses anteriores</h2>
                            <p className="text-light">Estos son todos los pagos pendientes de meses anteriores.</p>
                            {/* Borde */}
                            <div className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >
                                { (pagosMesesAntes.length > 0)
                                    ? <div> 
                                        {pagosMesesAntes.map( (pagoActual, key) => (
                                            <div key={pagoActual.nftInfo._id} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                                

                                                {/* Avatar */}
                                                <div className="col-sm-1">
                                                    <div className="form-floating">
                                                        <img src={pagoActual.nftInfo.imagenNFT} alt="avatar" style={{width: "80px", borderRadius: "50%"}} />
                                                    </div>
                                                </div>
                                                
                                                

                                                {/* Valor a pagar */}
                                                <div className="col-sm-2">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$ " + pagoActual.pagoPediente[0].valor  }  />
                                                        <label htmlFor="floatingInputGrid">Valor a Pagar</label>
                                                    </div>
                                                </div>

                                                {/* Fecha de pogo */}
                                                <div className="col-sm-2">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.pagoPediente[0].fecha.dia + "/" + pagoActual.pagoPediente[0].fecha.mes + "/" + pagoActual.pagoPediente[0].fecha.año }  />
                                                        <label htmlFor="floatingInputGrid">Fecha de pago</label>
                                                    </div>
                                                </div>
                                                

                                                {/* id */}
                                                <div className="col-sm-2">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.idNFT}  />
                                                        <label htmlFor="floatingInputGrid">Id NFT</label>
                                                    </div>
                                                </div>

                                                {/* usuario Propietario */}
                                                <div className="col-sm-2">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.usuarioPropietario}  />
                                                        <label htmlFor="floatingInputGrid">Usuario Propietario</label>
                                                    </div>
                                                </div>

                                                {/* Nombre Propiedad */}
                                                <div className="col-sm-3">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.nombrePropiedad}  />
                                                        <label htmlFor="floatingInputGrid">Propiedad</label>
                                                    </div>
                                                </div>

                                                {/* Fecha de compra */}
                                                <div className="col-sm-2">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.dia + "/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.mes + "/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].fecha.año }  />
                                                        <label htmlFor="floatingInputGrid">Fecha Compra</label>
                                                    </div>
                                                </div>

                                                {/* Precio */}
                                                <div className="col-sm-2">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={Web3.utils.fromWei(pagoActual.nftInfo.precio.toString(), 'Ether') + " ETH"}   />
                                                        <label htmlFor="floatingInputGrid">Precio</label>
                                                    </div>
                                                </div>


                                                {/* Tarifa ETH en USD */}
                                                <div className="col-sm-2">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue= {"$" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].valor}  />
                                                        <label htmlFor="floatingInputGrid">Tarifa ETH</label>
                                                    </div>
                                                </div>

                                                {/* Costo NFT en USD */}
                                                <div className="col-sm-2">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$" + (pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].valor * Number(Web3.utils.fromWei(pagoActual.nftInfo.precio.toString(), 'Ether')) ).toFixed()  }  />
                                                        <label htmlFor="floatingInputGrid">NFT en USD</label>
                                                    </div>
                                                </div>

                                                {/* Hash transacción */}
                                                <div className="col-sm-2">
                                                    <div className="form-floating">
                                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].hash.substring(0, 10) + "..."} />
                                                        <label htmlFor="floatingInputGrid">Hash transacción</label>
                                                    </div>
                                                </div>


                                                


                                                {/* Botón ver transacción */}
                                                <div className="col-sm-2">
                                                    <a className="btn btn-primary btn-lg w-100" target="_blank" rel="noreferrer" href={ urlAusar + "tx/" + pagoActual.nftInfo.historial[pagoActual.nftInfo.historial.length-1].hash } role="button">Ver hash</a>
                                                </div>



                                            </div>
                                        ))}

                                        <h4 style={{textAlign: "center"}} className="text-light mt-5">DEBE TENER EN LA CUENTA MÁS DE ${totalMesesAnteriores} USD PARA ASEGURAR TODOS LOS PAGOS</h4>

                                        {/* Botón */}
                                        <div className="col-sm-1 w-100">
                                            <button
                                            className="w-100 btn btn-lg btn-primary"
                                            onClick={(event) => { funcionPagar("mesesAnteriores");}}
                                            >
                                            Pagar pagos día actual
                                            </button>
                                        </div>

                                    </div>
                                    : <p className="text-light">Aún cargando o no hay pagos pendientes.</p>
                                }
                            </div>   


                        </div>
                    </div>

            
                </div>
            </div>

            
        </div>
    )
}

export default Pagos;