

function HeaderGlobal(){

    return(
        <div>
            <nav id="miNavBarGlobal" className="navbar navbar-expand-sm navbar-dark" aria-label="Third navbar example" >
                <div className="container-fluid">
                    {/* Imagen */}
                    <a className="navbar-brand" href="/" ><img src="/imagenes/marca/nativeLogo.png" alt="" height="50px" /></a>

                    {/* Botón adaptable */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    
                    <div className="collapse navbar-collapse" id="navbarsExample04">
                        {/* Menú principal */}
                        <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                            <li className="nav-item"><a className="nav-link text-light" href="/como-funciona">Cómo funciona</a></li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#myCarousel" data-bs-toggle="dropdown" aria-expanded="false" style={{color: "white"}}>Propiedades</a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/unidad-15">Unidad 15</a></li>
                                </ul>
                            </li>
                            <li className="nav-item"><a className="nav-link text-light" href="/contacto" >Contacto</a></li>
                        </ul>




                        {/* Segunda parte del menú */}
                        <div className="col-md-4 text-md-end text-center headerFinal">
                            <div>
                                <a href="/iniciar-sesion" className="btn btn-outline-primary me-2" role="button" >Iniciar Sesión</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default HeaderGlobal;