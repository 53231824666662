import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Usuarios(){
    const navigate = useNavigate();

    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    const [usuarios, setUsuarios] = useState([]);


    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////




        async function ObtenerUsuarios(){
            await axios.post("/obtener-usuarios")
            .then(async function(response){
                setUsuarios(response.data.usuarios);
            });
        }
        


        ObtenerUsuarios();




        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


    // Función para ver un único usuario
    async function funcionVerUsuario(datos){
        const idUsuario = datos.id;
        const username  = datos.value;
        const usuarioActual = datos.usuarioActual;
        
        // Redireccionar a página de ver usuario
        const urlVerUsuario = "/admin/ver-usuario/" +  infoUsuarioLocal.usuario.username + "/" + username;
        navigate(urlVerUsuario, {state: {idUsuario: idUsuario, usernameUsuario: username, usuarioActual: usuarioActual}});
    }





    return(
        <div className='contenedorPaginasUsuario'>
            


            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                        <h1 className="h2 text-light">Usuarios</h1>
                    </div>



                    <div className="contenedorUno p-5 mb-4 rounded-3">
                        <div className="container-fluid py-5">
                            <h1 className="display-5 fw-bold text-light">Usuarios</h1>
                            <p className="col-md-8 fs-4 text-light">Aquí podrás ver todos los usuarios.</p>
                            <p className="text-light">
                                Haz click para ver el historial de cada usuario.
                            </p>

                            {/* Borde */}
                            <div className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >

                                

                                { (usuarios.length>0)
                                    ? usuarios.map( (usuarioActual, key) => (
                                        <div key={usuarioActual.usuario._id} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                            {/* Avatar */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <img src={usuarioActual.usuario.avatar} alt="avatar" style={{width: "80px", borderRadius: "50%"}} />
                                                </div>
                                            </div>

                                            {/* Usuario */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.usuario.username}  />
                                                    <label htmlFor="floatingInputGrid">Usuario</label>
                                                </div>
                                            </div>

                                            {/* Nombre */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.usuario.nombre + " " +  usuarioActual.usuario.apellido}  />
                                                    <label htmlFor="floatingInputGrid">Nombre</label>
                                                </div>
                                            </div>

                                            {/* Correo */}
                                            <div className="col-sm-3">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.usuario.email}  />
                                                    <label htmlFor="floatingInputGrid">Correo</label>
                                                </div>
                                            </div>

                                            {/* identificación */}
                                            <div className="col-sm-2">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.usuario.identificacion}  />
                                                    <label htmlFor="floatingInputGrid">Identificación</label>
                                                </div>
                                            </div>
                                            

                                            {/* NFTs */}
                                            <div className="col-sm-1">
                                                <div className="form-floating">
                                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.nfts.length }  />
                                                    <label htmlFor="floatingInputGrid">NFTs</label>
                                                </div>
                                            </div>

                                            {/* Botón */}
                                            <div className="col-sm-1">
                                                <button
                                                className="w-100 btn btn-lg btn-primary"
                                                id={usuarioActual.usuario._id}
                                                value={usuarioActual.usuario.username}
                                                

                                                onClick={(event) => {
                                                    const datosAdicionales = {
                                                    id: event.target.id,
                                                    value: event.target.value,
                                                    usuarioActual: usuarioActual.usuario
                                                    };
                                                    funcionVerUsuario(datosAdicionales);
                                                }}
                                                >
                                                Ver Usuario
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                    : <p>Aún cargando</p>
                                }
                            </div>     
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Usuarios;





