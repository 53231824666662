import '../../estilos/paginas.css';
import { useEffect } from 'react';

function ComoFunciona() {

    // Modificar la clase de body par página de registro - styles.css /////////
    useEffect(() => {
        document.body.classList.add('claseBodyWeb')
        return () => {
        document.body.classList.remove('claseBodyWeb')
        }
    }, []);
    // Modificar la clase de body par página de registro - styles.css /////////


    return(
        <div>
            {/* Primer contenedor: imagen principal */}
            <div className='contenedorComoFuncionaInicial text-light'>
                    <h1>Cómo funciona</h1>
                    <p className='subContenedorPrincipal'>Cómo crear y comprar</p>
            </div>




            
            <div className='container'>

                {/* Paso 1 */}
                <div class="container my-5">
                    <div class="row">
                        {/* Video */}
                        <div class="col-md-7 d-flex align-items-center justify-content-center">
                            <iframe className='claseIframeComoFunciona' src="https://www.youtube.com/embed/LetRLeaWxG4?si=KJAU39zAzkcMo4MN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        {/* Texto */}
                        <div class="col-md-5 d-flex align-items-center justify-content-center py-3">
                            <div>
                                <h2>1. Crear Wallet Metamask</h2>
                                <p>Necesitas esta wallet para poder guardar tus fondos. Con estos podrás comprar, vender o recibir utilidades en la criptomoneda Ethereum.</p>
                                <ul>
                                    <li>1. Descarga la extensión de Metamask en tu nagevador. Preferiblemente Google Chrome.</li>
                                    <li>2. Crea una nueva wallet y guarda muy bien tu contraseña y clave secreta.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="featurette-divider"/>



                {/* Paso 2 */}
                <div class="container my-5">
                    <div class="row">
                        {/* Video */}
                        <div class="col-md-7 d-flex align-items-center justify-content-center">
                            <iframe className='claseIframeComoFunciona' src="https://www.youtube.com/embed/-ICHWj5Gwfk?si=sk2BomTwFfRd4KM8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        {/* Texto */}
                        <div class="col-md-5 d-flex align-items-center justify-content-center py-3">
                            <div>
                                <h2>2. Comprar criptomoneda Ehtereum</h2>
                                <p>Debes añadir saldo a tu wallet y para esto debes comprar una fracción de la criptomoneda Ethereum.</p>
                                <ul>
                                    <li>1. Inicia sesión en Metamask con tu wallet.</li>
                                    <li>2. Dirígete a la sección "COMPRAR".</li>
                                    <li>3. Selecciona la cantidad de Ethereum que deseas comprar, en pesos colombianos por ejemplo. La plataforma automáticamente te mostrará la tarifa actual de compra.</li>
                                    <li>4. Realiza la compra y espera unos minutos a que sea efectiva.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <hr className="featurette-divider"/>



                {/* Paso 3 */}
                <div class="container my-5">
                    <div class="row">
                        {/* Video */}
                        <div class="col-md-7 d-flex align-items-center justify-content-center">
                            <iframe className='claseIframeComoFunciona' src="https://www.youtube.com/embed/QFKq23K3nUI?si=YD_SRo9cB_63Zd2i" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        {/* Texto */}
                        <div class="col-md-5 d-flex align-items-center justify-content-center py-3">
                            <div>
                                <h2>3. Comprar un NFT en Native</h2>
                                <p>Cuando ya tengas saldo en tu wallet de Metamask, podrás realizar la compra de un NFT.</p>
                                <ul>
                                    <li>1. Dentro de native, busca la propiedad que deseas adquirir.</li>
                                    <li>2. Haz click en "ver NFT disponibles".</li>
                                    <li>3. Selecciona un NFT preferiblemente propiedad de Native (es decir que nadie lo ha comprado aún).</li>
                                    <li>3.1 Si seleccionas un NFT de otro usuario, debes esperar a que este usuario acepte la solicitud para vender su NFT. Luego si podrás comprarlo. Recibirás la notificación por Correo electrónico.</li>
                                    <li>4. Compra el NFT y espera unos minutos a que sea efectivo.</li>
                                    <li>5. Espera las utilidades de tu compra.</li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            
        </div>
    )
}


export default ComoFunciona;