import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation, useParams, useNavigate} from 'react-router-dom';
import axios from "axios";
import Web3 from 'web3';
// Replace with the URL of your Ganache instance
var web3 
//import { useParams } from "react-router-dom";

function Mispropiedades() {
    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    // Declaramos useLocation para traer props en navigate
    const location = useLocation();
    const navigate = useNavigate();
    // Capturamos el valor de params
    const routeParams = useParams();
    //console.log(routeParams);
    const enlaceVolver = "/cliente/mis-propiedades/" + routeParams.username; 
    
    // useState blockchain
    const [estado, setEstado] = useState({
        account: "",
        cantidadTokens: 0,
        arregloTokensPropiedades: [],
        loading: true,
        abiContrato: "",
        addressContrato: "",
        contratoEnBlockchain: "",
    });

    // VARIALBES USADAS PARA CREAR Y MOSTRAR NFTS DEL USUARIO //////
    const [nftsUsuario, setNftsUsuario] = useState(null);
    let urlEtherscan = "https://etherscan.io/";
    let urlSepolia = "https://sepolia.etherscan.io/";
    let urlAusar = urlEtherscan; // cambiar a producción

    

    useEffect(() => {

        async function loadWeb3(){
            window.addEventListener('load', async () => {
            // FORMA1: Modern dapp browsers... - chrome
            if (window.ethereum) {
                // crea una nueva conexión a la blockchain. Se pasa como argumento window.ethereum. Luego lo hbilitamos
                try {
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    setEstado(prevEstado => { return { ...prevEstado, account: accounts[0], } });  // Actualizamos account con el valor obtenido, PERO mantenimiento los otros valores anteriores.
                } catch (error) {
                    if (error.code === 4001) {
                        // User rejected request
                        console.log("error 4001");
                    }
                }
            }
        
            // FORMA2: Legacy dapp browsers...
            else if (window.web3) { window.web3 = new Web3(web3.currentProvider); }
        
            // FORMA3: Non-dapp browsers...
            else {console.log('No se detectó un navegador que no es Ethereum. ¡Deberías considerar probar MetaMask!');}
            });
        }

        async function obtenerNFTSdb(){
            const datosAenviar = {username: routeParams.username};
            await axios.post("/obtener-nfts-db", datosAenviar)
            .then(response => {
                // Si ha salido bien la respuesta.
                if(response.status===200){
                    const NFTS = response.data.nftsEncontrados;
                    setNftsUsuario(NFTS); // ponemos los nfts encontrados en usestate.
                }   
            })            
        }
        obtenerNFTSdb();

        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    
    


    //console.log(nftsUsuario);
    // Capturamos el valor de params
    //const routeParams = useParams();
    // console.log(routeParams);
    // console.log("location: ", location.state);

    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarUsuario />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                        <h1 className="h2 text-light">Mis NFTS</h1>
                    </div>


                  


                    {/* NFTS */} 
                    <div className="py-5 px-2 p-sm-5 mb-4 bg-body-tertiary rounded-3">

                        <div className="container text-center mb-5">
                            <h2>NFTs comprados</h2>
                            <p>Hola {routeParams.username}, aquí podrás ver el listado de los NFTs que has comprado con nosotros con toda la información.</p>
                        </div>


                        {(nftsUsuario!==null)
                        ?<div className="container">
                            {nftsUsuario.map( (nftActual, key) =>{
                                return(
                                    <div key={key} >
                                            <div style={{backgroundColor: "#223531"}} className="row mb-5 py-5 px-1 py-sm-4  px-sm-5 text-light rounded-5">
                                                {/* Imagen */}
                                                <div className="col-lg-6">
                                                        <div className="d-flex justify-content-center"> <img className="imagenMisPropiedades" src={nftActual.imagenNFT}  alt="" /></div>
                                                </div>

                                                {/* INFO DEL NFT */}
                                                <div className="col-lg-6">
                                                    <div className="container-fluid py-5">
                                                        <h1 className="display-5 fw-bold pb-3"> NFT {nftActual.idNFT}. <br/> {Web3.utils.fromWei(nftActual.precio.toString(), 'Ether')} ETH</h1>
                                                        <p><b>Propiedad:</b> {nftActual.nombrePropiedad}</p>
                                                        <p><b>Usuario Propietario:</b> {nftActual.usuarioPropietario}</p>
                                                        <p><b>Dirección propietario:</b> <a style={{textDecoration: "none", color: "#ada06b"}} target="_blank" rel="noreferrer" href={ urlAusar + "address/" + nftActual.direccionPropietario }>{nftActual.direccionPropietario.substring(0, 10)}...</a></p>
                                                        <p><b>Fecha de compra:</b> {nftActual.historial[nftActual.historial.length-1].fecha.dia} / {nftActual.historial[nftActual.historial.length-1].fecha.mes} / {nftActual.historial[nftActual.historial.length-1].fecha.año}.</p>
                                                        <p><b>Tarifa ETH en USD:</b> $ {nftActual.historial[nftActual.historial.length-1].valor}</p>
                                                        <p><b>Costo NFT en USD:</b> $ { (nftActual.historial[nftActual.historial.length-1].valor * Number(Web3.utils.fromWei(nftActual.precio.toString(), 'Ether')) ).toFixed()  }</p>
                                                        <p><b>hash transacción:</b> <a style={{textDecoration: "none", color: "#ada06b" }} target="_blank" rel="noreferrer" href={ urlAusar + "tx/" + nftActual.historial[nftActual.historial.length-1].hash }>{nftActual.historial[nftActual.historial.length-1].hash.substring(0, 10)}...</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                )
                            })}
                        </div>
                        :<p>Aún no tienes NFTs. Puedes ir a la sección "Comprar" para realizar tu primera compra.</p>}

                    </div>
            
                </div>
            </div>
        </div>
    )
}



export default Mispropiedades;