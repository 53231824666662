import "../estilos/paginas.css";
import {useLocation} from 'react-router-dom';
import { useEffect } from 'react';

function Alerta() {
  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {
    document.body.classList.add('claseBodyInicioRegistro')
    return () => {
      document.body.classList.remove('claseBodyInicioRegistro')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////

  // Declaramos useLocation para traer props en navigate
  const location = useLocation();

  return(
    <div className='container contenedorAlerta text-light' >
      <h1 className="mb-5">¡Alerta!</h1>
      <h3>{location.state.titulo}</h3>
      <p>{location.state.mensaje}</p>
      <a className="btn btn-primary" href={location.state.urlMensaje} role="button">{location.state.nombreBoton}</a>
    </div>
  )
}


export default Alerta;