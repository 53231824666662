import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation, useNavigate} from 'react-router-dom';
import { useParams } from "react-router-dom";
import axios from "axios";

function Vender() {
    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    // Declaramos useLocation para traer props en navigate
    const location = useLocation();
    const navigate = useNavigate();
    // Capturamos el valor de params
    const routeParams = useParams();
    const enlaceVolverHomeUsuario = "/cliente/home/" + routeParams.username; 
    const [arregloCompraYventa, setArregloCompraYventa ] = useState([""]);
    
    useEffect(() => {
        
        
        function buscarVentas(){

            const datosVenta = {
                usuarioPropietario: routeParams.username,
            }
            
            axios.post("/buscarVentas", datosVenta)
            .then(function (response) {
                setArregloCompraYventa(response.data.comprayventa);
                // if(response.data.status===200){ 
                //     for(let i = 0; i<response.data.comprayventa.length; i++){
                //         let posActual = response.data.comprayventa[i]; 
                //         setArregloCompraYventa(prevArregloCompraYventa => { return { ...prevArregloCompraYventa, posActual }}); 
                //     }
                    
                // }
            })
            .catch(function(err){console.log(err);});
        }
        
        buscarVentas();
        
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    
    console.log(arregloCompraYventa);
    // Capturamos el valor de params
    //const routeParams = useParams();
    // console.log(routeParams);
    // console.log("location: ", location.state);

    function controlVenta(datos){

        let datosRespuesta = {
            respuesta: datos.respuesta,
            idNFT: datos.idNFT,
            usuarioComprador: datos.usuarioComprador,
            posicionRespuesta: datos.posRespuesta,
            usuarioPropietario: routeParams.username
        }

        // si no tiene errores el usuario entonces pasamos datos a servidor o bd.
        axios.post("/respuesta-venta", datosRespuesta)
        .then(function (response) {
            if(response.data.status===200){ return navigate("/alerta", {state: {titulo:"Respuesta exitosa", mensaje: response.data.mensaje, urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } ); }
            else{ return navigate("/alerta", {state: {titulo: "Error", mensaje: "Por favor intenta de nuevo enviar la solicitud.", urlMensaje: enlaceVolverHomeUsuario, nombreBoton:"Volver"} } );  }
        })
        .catch(function(err){console.log(err);});


    }
   


    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarUsuario />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
                        <h1 className="h2 text-light">Vender Propiedades</h1>
                    </div>

                    {/* contenedor de solicitudes */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">
                        <div className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">id NFT</th>
                                        <th scope="col">Comprador</th>
                                        <th scope="col">Habilitar</th>
                                        <th scope="col">Denegar</th>
                                    </tr>
                                </thead>
                                <tbody id="productList">
                                    {/* recorremos arreglo de comprayventa, omitiendo la primera posición y solo las solicitudes en proceso*/}
                                    {   (arregloCompraYventa)
                                        ? arregloCompraYventa
                                            .slice(1)
                                            .filter((nft) => nft.respuesta === "proceso")
                                            .map( (nft, key) =>{
                                                return(
                                                    // el atributo key
                                                    <tr key={key} >
                                                        <th scope="row">{nft.idNFT}</th>
                                                        <td>{nft.usuarioComprador}</td>
                                                        
                                                        {/* botón habilitar venta */}
                                                        <td>
                                                            <button
                                                                
                                                                className="w-100 btn btn-primary"
                                                                id={"aceptar"}
                                                                name={nft.idNFT}
                                                                value={nft.usuarioComprador}
                                                                onClick={(event) => {
                                                                    const datosRespuesta = {
                                                                    respuesta: event.target.id,
                                                                    idNFT: event.target.name,
                                                                    usuarioComprador: event.target.value,
                                                                    posRespuesta: nft.posicion, // enviamos más datos dentro de button
                                                                    };
                                                                    controlVenta(datosRespuesta);
                                                                }}
                                                                >
                                                                Habilitar venta
                                                            </button>
                                                        </td>

                                                        {/* botón denegar venta */}
                                                        <td>
                                                            <button
                                                                className="w-100 btn btn-danger"
                                                                id={"denegar"}
                                                                name={nft.idNFT}
                                                                value={nft.usuarioComprador}
                                                                onClick={(event) => {
                                                                    const datosRespuesta = {
                                                                    respuesta: event.target.id,
                                                                    idNFT: event.target.name,
                                                                    usuarioComprador: event.target.value,
                                                                    posRespuesta: nft.posicion, // enviamos más datos dentro de button
                                                                    };
                                                                    controlVenta(datosRespuesta);
                                                                }}
                                                                >
                                                                Denegar venta
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Vender;