import '../../estilos/paginas.css';
import { useEffect } from 'react';



function Home() {
  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////
 
    
  return(
    <div className='contenedorPaginasUsuario'>
      
      

      {/* Primer contenedor: imagen principal */}
      <div className='contenedorHomeInicial'>
            <img className='logoContendorPrincipal' src='/imagenes/marca/nativeLogow.png' alt=''></img>
            <p className='subContenedorPrincipal'>Nature is the new luxury</p>
      </div>

      {/* Segund contenedor: iconos y textos */}
      <div className='container mt-4'>
        <div className='row'>

          {/* Icono y texto 1 */}
          <div className='col-6 col-lg-3 contenedorTarjetas contenedorTarjetasUnoYdos'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-3'>
                <i className="fa-brands fa-ethereum" ></i>
              </div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4>Proyecto Cripto</h4>
                <p>Realiza tu compra con Ethereum</p>
              </div>
            </div>
          </div>


          {/* Icono y texto 2 */}
          <div className='col-6 col-lg-3 contenedorTarjetas contenedorTarjetasUnoYdos'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'>
              <i className="bi bi-house-add-fill" ></i>
                
              </div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4>Inmobiliario</h4>
                <p>Dueño sin los costos fijos</p>
              </div>
            </div>
          </div>


          {/* Icono y texto 3 */}
          <div className='col-6 col-lg-3 contenedorTarjetas'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'>
                
                <i className="bi bi-currency-dollar"></i>
              </div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4>Bajo costo</h4>
                <p>Pago y dueño por NFTS</p>
              </div>
            </div>
          </div>

          {/* Icono y texto 4 */}
          <div className='col-6 col-lg-3 contenedorTarjetas'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'>
                <i className="bi bi-piggy-bank"></i>
              </div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4>Utilidades</h4>
                <p>Genera dinero cada mes</p>
              </div>
            </div>
          </div>

        </div>
      </div>





      {/* Contendor Nuevo servicio native video */}
      <div className='container mt-5'>
        {/* video */}
        <div className='container text-center d-flex align-items-center justify-content-center'>
            <div className="container-fluid py-1">
                <h2 className="display-5 fw-bold">Nuevo servicio</h2>
                <p className=" mb-5">Inversión con poco capital</p>
                
                <iframe width="315" height="560"
                  src="https://www.youtube.com/embed/1trc1tXf1UY"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media;
                  gyroscope; picture-in-picture;
                  web-share"
                  allowFullScreen>

                  </iframe>
            </div>
        </div>

        <hr className="featurette-divider" />
      </div>









      {/* Tercer contendor: imágenes y textos informativos */}
      <div className="container marketing">
        {/* Texto e imagen 1 */}
        <div className="row featurette primerContMark">
          {/* Texto */}
          <div className="col-md-7 mt-5">
            <h2 className="featurette-heading fw-normal lh-1 mb-5" style={{color: "#223531"}}>Cripto inmobiliaria. <span className="text-body-secondary">Una nueva forma de adquirir propiedades.</span></h2>
            <p className="lead">Nos apalancamos de la cadena de bloques de Ethereum para que esto sea posible. Creamos un contrato inteligente para respaldar tu compra, almacenando y asegurando cada movimiento en tu propiedad.</p>
            <p className="lead">Compra en el proyecto que desees, genera utilidades en el tiempo y vende cuando lo desees. Sin limitaciones, puedes adquirir varias propiedades sin problema.</p>
          </div>

          {/* Imagen */}
          <div className="col-md-5"><img  className='miImagenMark' src="/imagenes/webGlobal/cripto-Inmobiliaria.jpg" alt=''></img></div>
        </div>

        <hr className="featurette-divider"/>

        {/* Texto e imagen 2 */}
        <div className="row featurette">
          {/* Imagen */}
          <div className="col-sm-12 col-md-5 "><img  className='miImagenMark' src="/imagenes/webGlobal/costosHogar.jpg" alt=''></img></div>
          {/* Texto */}
          <div className="col-sm-12 col-md-7  mt-5">
            <h2 className="featurette-heading fw-normal lh-1 mb-5"  style={{color: "#223531"}}>Cero costos de mantenimiento. <span className="text-body-secondary">El trabajo pesado va por nuestra cuenta.</span></h2>
            <p className="lead">Servicios públicos mensuales, impuestos anuales, cuidado de la propiedad, renta y generación de utilidades: todo ese proceso lo hacemos por ti.</p>
            <p className="lead">Siendo expertos en la industria, contamos con diferentes recursos para hacer que todas tus propiedades con nososotros sean rentables en el tiempo.</p>
          </div>
          
        </div>

        <hr className="featurette-divider" />
      </div>



      {/* Cuarto contenedor: carrusel */}
      <div id="myCarousel" className="carousel slide mb-6" data-bs-ride="carousel">
        {/* Indicadores */}
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>


        <div className="carousel-inner">
          {/* Propiedad 1 */}
          <div className="carousel-item active" >
            {/* imagen 1 */}
            {/* Contendor adicional para centrr solo la imagen */}
            <div className="d-flex align-items-center justify-content-center h-100">
              <img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+2.png"  className="imagenCarrusel d-block w-100" alt="..." />
            </div>
            <div className="container textoCarrusel">
              <div className="carousel-caption text-start">
                <h1>Unidad 15.</h1>
                <p className="opacity-75">Ubicada en el oriente antioqueño.</p>
                <p><a className="btn btn-lg btn-primary" href="/unidad-15">Ver propiedad</a></p>
              </div>
            </div>
          </div>
          {/* Propiedad 2 */}
          <div className="carousel-item">
            {/* imagen 2 */}
            {/* Contendor adicional para centrr solo la imagen */}
            <div className="d-flex align-items-center justify-content-center h-100">
              <img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+1.jpg" className="imagenCarrusel d-block w-100" alt="..." />
            </div>
            <div className="container">
              <div className="carousel-caption">
                <h1>Unidad 15.</h1>
                <p>Ubicada en el oriente antioqueño.</p>
                <p><a className="btn btn-lg btn-primary" href="/unidad-15">Ver propiedad</a></p>
              </div>
            </div>
          </div>
          {/* Propiedad 3 */}
          <div className="carousel-item">
            {/* imagen 3 */}
            {/* Contendor adicional para centrr solo la imagen */}
            <div className="d-flex align-items-center justify-content-center h-100">
              <img src="https://bucket-imagenes-propiedades-native.s3.us-east-2.amazonaws.com/unidad-15/WEB/Unidad+15+3.png" className="imagenCarrusel d-block w-100" alt="..." />
            </div>
            <div className="container">
              <div className="carousel-caption text-end">
                <h1>Unidad 15.</h1>
                <p>Ubicada en el oriente antioqueño.</p>
                <p><a className="btn btn-lg btn-primary" href="/unidad-15">Ver propiedad</a></p>
              </div>
            </div>
          </div>
        </div>

        {/* Botones */}
        <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>



      {/* Contendor Nuevo servicio native video */}
      <div className='container mt-5'>
        {/* video */}
        <div className='container text-center d-flex align-items-center justify-content-center'>
            <div className="container-fluid py-1">
                <h2 className="display-5 fw-bold">Qué es la tokenización inmobiliaria</h2>
                <p className=" mb-5">Tokenización con blochain</p>
                
                <iframe className='claseIframeVideos' src="https://www.youtube.com/embed/WKbYcXKTGhE?si=btfQCg2b9nWJwfry" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

            </div>
        </div>
      </div>









      {/* Quito contenedor: cómo funciona */}
      <div id='comoFunciona' className="container px-4 py-5">
        <h2 className="pb-2 border-bottom">Cómo funciona</h2>
        <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
          {/* Primera sección */}
          <div className="col d-flex flex-column align-items-start gap-2">
            <h2 className="fw-bold text-body-emphasis">Industria inmobiliaria descentralizada</h2>
            <p className="text-body-secondary">Usando la cadena de bloques de la criptomoneda Ethereum, creamos contratos inteligentes para tokenizar cada una de nuestras propiedades en varias cantidades de NFTS.</p>
            <h3 className="fw-bold text-body-emphasis">Adquiere NFTS de nuestras propiedades</h3>
            <p className="text-body-secondary">Puedes comprar uno o varios NFTS de la propiedad que desees. Cada token representa un valor exacto en Ethereum. Por ejemplo 1 token: 0.60 ETH.</p>
            <h3 className="fw-bold text-body-emphasis">Cero costos mensuales</h3>
            <p className="text-body-secondary">Olvídate de los costos mensuales que recaen en el propietario de una propiedad. Nosotros construimos, rentamos y mantenemos cada una de las propiedades.</p>
          </div>

          {/* Segunda sección */}
          <div className="col">
            <div className="row row-cols-1 row-cols-sm-2 g-4">
              {/* Ver propiedades */}
              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center btn-primary bg-gradient fs-4 rounded-3">
                  <i className="iconComoFunciona bi bi-houses"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">1. Ver propiedades</h4>
                <p className="text-body-secondary">Revisa nuestras propiedades por construir o construidas.</p>
              </div>
              {/* Crear cuenta */}
              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center btn-primary bg-gradient fs-4 rounded-3">
                  <i className="iconComoFunciona bi bi-person-check"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">2. Crea tu cuenta</h4>
                <p className="text-body-secondary">Regístrate para poder iniciar a comprar.</p>
              </div>
              {/* Comprar tokens */}
              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center btn-primary bg-gradient fs-4 rounded-3">
                  <img className='token' src='/imagenes/marca/tokenb.png' alt=''></img>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">3. Comprar NFTS</h4>
                <p className="text-body-secondary">Compra uno o varios NFTS de la propiedad seleccionada</p>
              </div>
              {/* Esperar utilidades */}
              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center btn-primary bg-gradient fs-4 rounded-3">
                  <i className="iconComoFunciona bi bi-piggy-bank-fill"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">4. Esperar utilidades</h4>
                <p className="text-body-secondary">Generaremos el pago de tus utilidades en el tiempo determinado</p>
              </div>
              {/* Comprar tokens */}
              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center btn-primary bg-gradient fs-4 rounded-3">
                  <i className="iconComoFunciona bi bi-bar-chart"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">5. Estadísticas</h4>
                <p className="text-body-secondary">Revisa en tu cuenta las utilidades esperadas</p>
              </div>
              {/* Esperar utilidades */}
              <div className="col d-flex flex-column gap-2">
                <div className="feature-icon-small d-inline-flex align-items-center justify-content-center btn-primary bg-gradient fs-4 rounded-3">
                  <i className="iconComoFunciona fa-solid fa-handshake"></i>
                </div>
                <h4 className="fw-semibold mb-0 text-body-emphasis">6. Vende NFTS</h4>
                <p className="text-body-secondary">Puedes vender tus NFTS. Seguirán generando utilidades al dueño.</p>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* Sexto contenedor: FAQ */}
      <div className='container'>
        <div className="p-sm-5 p-3 mb-4 contenedorFAQ rounded-3">
          <div className="container-fluid py-5">
            <h1 className="display-5 fw-bold text-light">FAQ</h1>
            <p className="col-md-8 fs-4 text-light mb-3">Encuentra aquí algunas preguntas frecuentes antes de iniciar.</p>
            {/* Accordion */}
            <div className="accordion" id="accordionPanelsStayOpenExample">
              {/* Pregunta 1 */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                    ¿Qué significa tokenizar una propiedad?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                  <div className="accordion-body">
                    <strong>Dividir el costo total de una propiedad en varias secciones.</strong> Cada una de estas secciones tiene un costo. Por ejemplo si una propiedad cuesta 10 Millones y esta es fragmentada, dividida o tokenizada en 10 partes,
                    cada NFT costará 1 Millón.
                  </div>
                </div>
              </div>
              {/* Pregunta 2 */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                    ¿Por qué tokenizar una propiedad?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <strong>Este proceso permite ser propiedario a cualquier persona sin el alto costo del mercado.</strong> Una propiedad dependiendo de su ubicación, tamaño, tipo de construcción entre otros, varía su 
                    costo total. Con la tokenización es posible que cualquier persona pueda adquirir una fracción de propiedad en el mercado y generar las mismas o mejores utilidades.
                  </div>
                </div>
              </div>
              {/* Pregunta 3 */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                    ¿Cuál es la utilidad del proyecto?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <strong>La propiedad cambia con respecto a cada proyecto.</strong> Aunque generalmente ofrecemos el 12% anual y este pago se realiza trimestral. Como cada token está en una dirección específica, el pago
                    se realiza a esta dirección. Es decir que recibirás tus utilidades en ETH.
                  </div>
                </div>
              </div>
              {/* Pregunta 4 */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
                    ¿Cómo generan las utilidades?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapse4" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <strong>Rentamos las propiedades.</strong> Nos encargamos de la construcción completa de la propiedad y al finalizar generamos las utilidades, rentando la propiedad. Así mismo nos encargamos de su mantenimiento
                    completo incluyendo servicios, impuestos y demás.
                  </div>
                </div>
              </div>
              {/* Pregunta 5 */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
                   ¿Puedo retirar mi dinero cuando yo quiera?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapse5" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <strong>Cada token es completamente tuyo, por ende puedes venderlo cuando desees.</strong> Es importante saber que cuando un NFT es comprado a NATIVE inicia a generar utilidades sin importar el propietario.
                    Cada token es 100% propiedad del usuario y si desea vender uno o varios NFTS a otra persona, lo puede hacer y este token seguirá generando utilidades.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* Séptimo contenedor: contacto */}
      <div id='contacto' className='mt-5'>
        <div className="contenedorContacto pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
          <div className="my-3 py-3">
            <h2 className="display-5">Contacto</h2>
            <p className="lead text-dark">Deja tu información a continuación y en breve estaremos contigo.</p>
          </div>
          {/* Contenedor interno */}
          <div className="contenedorInternoContacto shadow-sm mx-auto">
            <div className='pt-5 px-5 container'>
              <form>
                {/* Email */}
                <div className="form-floating mb-3">
                  <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                  <label htmlFor="floatingInput">Email</label>
                </div>

                {/* Nombre */}
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="floatingInputGroup1" placeholder="Andres" />
                  <label htmlFor="floatingInputGroup1">Nombre</label>
                </div>

                {/* Mensaje */}
                <div className="form-floating mb-3">
                  <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height:"100px"}}></textarea>
                  <label htmlFor="floatingTextarea2">Mensaje</label>
                </div>


                {/* Botón enviar */}
                <button className="btn btn-primary w-100" type="submit">Contactar</button>
              </form>
            </div>
          </div>
        </div>
      </div>



      

    </div>
  )
}



export default Home;