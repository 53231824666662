import '../../estilos/paginas.css';
import { useEffect } from 'react';



function Cookies() {
  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////


  return(
    <div className='contenedorPaginasUsuario'>



        {/* Primer contenedor: imagen principal */}
        <div className='contenedorCookies'>
            <img className='logoContendorPrincipal' src='/imagenes/marca/nativeLogow.png' alt=''></img>
            <p className='subContenedorPrincipal'>Cookies</p>
        </div>

        {/* contenido */}
        <div className='container my-5'>
          <div class="p-5 mb-4 bg-body-tertiary rounded-3">
              <div class="container-fluid py-5">
                  <h1 class="display-5 fw-bold">Cookies</h1>
                  <p class="col-md-12">
                    Qué son las cookies
                    <br />
                    La Web de Native (en adelante la Web) utiliza cookies. Las cookies son ficheros enviados a un navegador por medio de un servidor web para registrar las actividades del Usuario en una web determinada. La primera finalidad de las cookies es la de facilitar al usuario un acceso más rápido a los servicios seleccionados. Además, las cookies personalizan los servicios que ofrece la Web, facilitando y ofreciendo a cada usuario información que es de su interés o que puede ser de su interés, en atención al uso que realiza de los Servicios.
                    La Web utiliza cookies para personalizar y facilitar al máximo la navegación del usuario. Las cookies se asocian únicamente a un usuario anónimo y su ordenador y no proporcionan referencias que permitan deducir datos personales del usuario. El usuario podrá configurar su navegador para que notifique y rechace la instalación las cookies enviadas por la Web, sin que ello perjudique la posibilidad del usuario de acceder a los contenidos de dicha web. Sin embargo, le hacemos notar que, en todo caso, la calidad de funcionamiento de la página Web puede disminuir.
                    Los usuarios registrados, que se registren o que hayan iniciado sesión, podrán beneficiarse de unos servicios más personalizados y orientados a su perfil, gracias a la combinación de los datos almacenados en las cookies con los datos personales utilizados en el momento de su registro. Dichos usuarios autorizan expresamente el uso de esta información con la finalidad indicada, sin perjuicio de su derecho a rechazar o deshabilitar el uso de cookies.
                    Asimismo, la Web podrá saber todos los servicios solicitados por los usuarios, de forma que podrán facilitar u ofrecer información adecuada a los gustos y preferencias de cada usuario.
                    <br />
                    <br />
                    Qué tipos de cookies existen
                    <br />
                    Las cookies, en función de su permanencia, pueden dividirse en:
                    Cookies de sesión: Las primeras expiran cuando el usuario cierra el navegador.
                    <br />
                    Cookies persistentes: Las segundas expiran en función de cuando se cumpla el objetivo para el que sirven (por ejemplo, para que el usuario se mantenga identificado en los Servicios) o bien cuando se borran manualmente.
                    Adicionalmente, en función de su Objetivo, las cookies pueden clasificarse de la siguiente forma:
                    <br />
                    Cookies de rendimiento: Este tipo de cookie recuerda sus preferencias para las herramientas que se encuentran en los servicios, por lo que no tiene que volver a configurar el servicio cada vez que usted visita. A modo de ejemplo, en esta tipología se incluyen:
                        * Ajustes de volumen de reproductores de vídeo o sonido.
                        * Las velocidades de transmisión de vídeo que sean compatibles con su navegador.cookies de geo-localización: Estas cookies son utilizadas para averiguar en qué país se encuentra cuando se solicita un servicio. Esta cookie es totalmente anónima, y sólo se utiliza para ayudar a orientar el contenido a su ubicación.
                    <br />
                    Cookies de registro: Las cookies de registro se generan una vez que el usuario se ha registrado o posteriormente ha abierto su sesión, y se utilizan para identificarle en los servicios con los siguientes objetivos:
                        * Mantener al usuario identificado de forma que, si cierra un servicio, el navegador o el ordenador y en otro momento u otro día vuelve a entrar en dicho servicio, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse. Esta funcionalidad se puede suprimir si el usuario pulsa la funcionalidad “cerrar sesión”, de forma que esta cookie se elimina y la próxima vez que entre en el servicio el usuario tendrá que iniciar sesión para estar identificado.
                        * Comprobar si el usuario está autorizado para acceder a ciertos servicios, por ejemplo, para participar en un concurso.
                    <br />
                    Cookies analíticas: Cada vez que un Usuario visita un servicio, una herramienta de un proveedor externo genera una cookie analítica en el ordenador del usuario. Esta cookie que sólo se genera en la visita, servirá en próximas visitas a los Servicios de la Web para identificar de forma anónima al visitante. Los objetivos principales que se persiguen son:
                        * Permitir la identificación anónima de los usuarios navegantes a través de la “cookie” (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo.
                        * Identificar de forma anónima los contenidos más visitados y por lo tanto más atractivos para los usuarios.
                        * Saber si el usuario que está accediendo es nuevo o repite visita.
                        * Importante: Salvo que el usuario decida registrarse en un servicio de la Web, la “cookie” nunca irá asociada a ningún dato de carácter personal que pueda identificarle. Dichas cookies sólo serán utilizadas con propósitos estadísticos que ayuden a la optimización de la experiencia de los Usuarios en el sitio.
                    <br />
                    Cookies de publicidad comportamental: Este tipo de “cookies” permite ampliar la información de los anuncios 
                    mostrados a cada usuario anónimo en los Servicios de la Web. Entre otros, se almacena la duración o frecuencia de visualización de posiciones publicitarias, la interacción con las mismas, o los patrones de navegación y/o compartimientos del usuario ya que ayudan a conformar un perfil de interés publicitario. De este modo, permiten ofrecer publicidad afín a los intereses del usuario.
                    Cookies publicitarias de terceros: Además de la publicidad gestionada por la Web en sus Servicios, la Web ofrece a sus anunciantes la opción de servir anuncios a través de terceros (“AdServers”). De este modo, estos terceros pueden almacenar cookies enviadas desde los Servicios de la Web procedentes de los navegadores de los Usuarios, así como acceder a los datos que en ellas se guardan.
                    <br />
                    <br />
                    Qué cookies utilizamos en nuestra Web
                    Las cookies que utilizamos en nuestra página web son:
                    Necesarias: Garantizan que nuestro sitio web funcione correctamente, tienen que ver con funcionalidades esenciales y no recopilan datos personales del usuario. Estas cookies necesarias son las que usamos:
                    <br />* cli_user_preference: Necesaria para registrar la aceptación del uso de cookies por parte del usuario.
                    <br />* cookielawinfoconsent: Registra si el usuario ha aceptado todas las cookies o únicamente las cookies necesarias.
                    <br />* cookielawinfo-checkbox-necessary: Necesaria para gestionar la aceptación de las cookies necesarias.
                    <br />* cookielawinfo-checkbox-non-necessary: Necesaria para gestionar la aceptación de las cookies no necesarias.
                    <br />* viewed_cookie_policy: Necesaria para conocer si el usuario ha visitado nuestra política de cookies.
                    No necesarias: Estas cookies no son estrictamente necesarias para el correcto funcionamiento de nuestro sitio web, pero nos permiten analizar qué contenidos son los que más visitan usuarios como tú, a través de servicios como Google Analytics. Estos servicios pueden recopilar datos personales del usuario. Estas cookies no necesarias son las que usamos:
                    <br />* _ga, _gat y _gid: Usadas por Google Analytics para generar datos estadísticos acerca del uso del sitio web.
                    <br />* NID: Registra una identificación única del dispositivo de un usuario que regresa a nuestro sitio web. Dicha identificación se usa para ofrecerle anuncios dirigidos.
                    <br />* rc::a, rc::b y rc::c: Usadas por Google para distinguir entre humanos y bots.
                    <br />* test_cookie: Usada por Google DoubleClick para comprobar si el navegador del usuario soporta el uso de cookies.
                    <br />
                    <br />
                    Cómo deshabilitar las cookies
                    <br />
                    Desde la pestaña inferior ‘Configuración de las cookies’ puedes permitir o rechazar las cookies en función de tus preferencias, y revocar en cualquier momento el consentimiento otorgado.
                    La mayoría de los navegadores están configurados de forma predeterminada para aceptar cookies. Bloquear las cookies puede afectar al funcionamiento y optimización de las páginas web que visites. A continuación encontrarás información sobre la gestión de cookies en:
                    * Chrome
                    * Firefox
                    * Edge
                    * Internet Explorer
                    * Safari
                    * Opera
                    * Navegadores para iOS
                    * Navegadores para Android
                    <br />
                    Modificaciones en el uso de cookies
                    <br />
                    La Web puede modificar el uso de cookies en función de las exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, por ello se aconseja a los usuarios que la visiten periódicamente.
                  </p>
              </div>
          </div>
        </div>
    </div>
  )
}



export default Cookies;