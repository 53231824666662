import React from "react";
import {Routes, Route, Outlet } from 'react-router-dom';
// páginas privadas
import Registro from "./paginas/registrar";
import InicioSesion from "./paginas/Iniciosesion";
import InicioSesionAdmin from "./paginas/inicioSesionAdmin";
import Alerta from "./paginas/alerta";
// paginas GLOGAL
import Home from "./paginas/webGlobal/home";
import Cookies from "./paginas/webGlobal/cookies";
import Terminos from "./paginas/webGlobal/terminos";
import Privacidad from "./paginas/webGlobal/politicaPrivacidad";
import ComoFunciona from "./paginas/webGlobal/comoFunciona";
import Contacto from "./paginas/webGlobal/contacto";
// Propiedades
import Unidad15 from "./paginas/propiedades/unidad15";
// Usuario
import HomeUsuario from "./paginas/usuario/homeusuario";
import Comprar from "./paginas/usuario/comprar";
import Vender from "./paginas/usuario/vender";
import MisNFTS from "./paginas/usuario/misNFTS";
import Soporte from "./paginas/usuario/soporte";
import Micuentausuario from "./paginas/usuario/miCuentaUsuario";
import Pagosusuario from "./paginas/usuario/pagosUsuario";
// Administrador
import HomeAdmin from "./paginas/administrador/homeAdministrador";
import CrearPropiedades from "./paginas/administrador/crearPropiedades";
import Usuarios from "./paginas/administrador/Usuarios";
import Pagos from "./paginas/administrador/pagos";
import Micuenta from "./paginas/administrador/miCuenta";
import VerUsuario from "./paginas/administrador/verUsuario";
// Headers
import HeaderGlobal from "./partials/headerGlobal";
import FooterGlobal from "./partials/footerGlobal";
import HeaderUsuario from "./partials/headerUsuario";
import HeaderAdmin from "./partials/headerAdmin";
import FooterUsuarioAdmin from "./partials/footerUsuarioAdmin";



function Direccionamiento(){

    /* Outleet se modifica con respecto a la ruta */
    function PlantillaGlobal() {
        return (
            <div>
                <HeaderGlobal />
                    <Outlet />
                <FooterGlobal />
            </div>
        )
    }

    function PlantillaUsuario() {
        return (
            <div>
                <HeaderUsuario />
                    <Outlet />
                <FooterUsuarioAdmin />
            </div>
        )
    }

    function PlantillaAdministrador() {
        return (
            <div>
                <HeaderAdmin />
                    <Outlet />
                <FooterUsuarioAdmin />
            </div>
        )
    }


    return (
        // Web Global
        <Routes>
            <Route path="/registrar" element={<Registro />} />
            <Route path="/iniciar-sesion" element={<InicioSesion />} />
            <Route path="/iniciar-sesion-administrador" element={<InicioSesionAdmin />} />
            <Route path="/alerta" element={<Alerta />} />
            {/* Rutas Global */}
            <Route path="/" element={<PlantillaGlobal />}>
                <Route index element={<Home />}/>
                <Route  path="cookies" element={<Cookies />}/>
                <Route  path="terminos" element={<Terminos />}/>
                <Route  path="privacidad" element={<Privacidad />}/>
                <Route  path="unidad-15" element={<Unidad15 />}/>
                <Route  path="como-funciona" element={<ComoFunciona />}/>
                <Route  path="contacto" element={<Contacto />}/>

            </Route>
            {/* Rutas Usuario */}
            <Route path="/cliente" element={<PlantillaUsuario />}>
                <Route  path="home/:username" element={<HomeUsuario />}/>
                <Route  path="comprar/:username/:propiedad" element={<Comprar />}/>
                <Route  path="vender/:username" element={<Vender />}/>
                <Route  path="mis-nfts/:username" element={<MisNFTS/>}/>
                <Route  path="soporte/:username" element={<Soporte />}/>
                <Route  path="mi-cuenta/:username" element={<Micuentausuario />}/>
                <Route  path="pagos/:username" element={<Pagosusuario />}/>
            </Route>
            {/* Rutas Administrador */}
            <Route path="/admin" element={<PlantillaAdministrador />}>
                <Route  path="home/:username" element={<HomeAdmin />}/>
                <Route  path="crear-propiedades/:username" element={<CrearPropiedades />}/>
                <Route  path="usuarios/:username" element={<Usuarios />}/>
                <Route  path="pagos/:username" element={<Pagos />}/>
                <Route  path="mi-cuenta/:username" element={<Micuenta />}/>
                <Route  path="ver-usuario/:username/:usuarioCliente" element={<VerUsuario />}/>
            </Route>
        </Routes>
    );   
}


export default Direccionamiento;