import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import '../estilos/partials.css';
function SidebarAdmin(){

    const paramsUsuario = useParams(); // obtenemos params para poner en url
    const navigate = useNavigate();


    function handleLogOut(event){
        // evita el parpadeo predefinido
        event.preventDefault();

        localStorage.clear(); // limpiamos el almacentaiento local.
        axios.post("/logout")
        .then(function(response){
            console.log(response);
            navigate("/");
        });
    }


    return(
        <div id='miSidebar' className="d-flex flex-column flex-shrink-0" style={{width: "4.5rem"}}>
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                <li className="nav-item">
                    <a href={"/admin/home/" + paramsUsuario.username} className="nav-link py-3 border-bottom border-dark-subtle rounded-0" aria-current="page" title="Dashboard" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-speedometer" style={{fontSize: "25px", color: "white"}}></i>
                    </a>
                </li>
                <li>
                    <a href={"/admin/crear-propiedades/" +paramsUsuario.username } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Crear propiedades" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-house-gear-fill" style={{fontSize: "25px", color: "white"}}></i>
                    </a>
                </li>
                <li>
                    <a href={"/admin/usuarios/" + paramsUsuario.username } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Usuarios" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-people-fill" style={{fontSize: "25px", color: "white"}}></i>
                    </a>
                </li>
                <li>
                    <a href={"/admin/pagos/" + paramsUsuario.username } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Pagos" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-credit-card-2-back-fill" style={{fontSize: "25px", color: "white"}}></i>
                    </a>
                </li>
            </ul>



            <div className="dropdown border-top">
            <a href="/" className="d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="https://github.com/mdo.png" alt="mdo" width="24" height="24" className="rounded-circle" />
            </a>
            <ul className="dropdown-menu text-small shadow">
                <li><a className="dropdown-item" href={"/admin/mi-cuenta/" + paramsUsuario.username }>Mi cuenta</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li>
                    <form>
                        <button onClick={handleLogOut} className="btn btn-light" type="submit">Cerrar Sesión</button>
                    </form>
                </li>
            </ul>
            </div>
        </div>
    )
}

export default SidebarAdmin;