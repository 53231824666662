import React, {useState} from "react";
import axios from "axios";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../estilos/inicioRegistro.css';





function InicioSesionAdmin() {

    // Creamos estado para guardar la sesión del usuario atenticado.
    const [infoUsuarioLocal, setInfoLocal]= useState({
        usuario: "",
        autenticado: false
    });

    useEffect(() => {      
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyInicioRegistro')
    return () => {document.body.classList.remove('claseBodyInicioRegistro');}
    }, []);
    // Modificar la clase de body par página de registro - styles.css /////////

  
    // declaración objeto inicial
    const[input, setInput] = useState ({
        usuario: "",
        contraseña: ""
    });

    // cambiar el valor por el que escribe el usuario
    function handleChange(event){
        const {name, value} = event.target;
        // guardar el valor previo.
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        });
    }

    const navigate = useNavigate();
    // se activa cuando se oprime el botón
    function handleClick(event){
        // evita el parpadeo predefinido
        event.preventDefault();

        // crear objeto para pasar a servidor
        const nUsuario = {
            username: input.usuario,
            password: input.contraseña
        }

        // pasar datos a servidor o bd.
        axios.post("/iniciar-sesion-administrador", nUsuario, {withCredentials: "true"})
        .then(function (response) {
            if(response.data.existe===false){ navigate("/alerta", {state: {titulo:"Error de inicio", mensaje:"El usuario o la contraseña son incorrectos. Por favor, vuelve a intentar", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } ); }
            else if(response.data.esAdministrador===false){ navigate("/alerta", {state: {titulo:"Error de inicio", mensaje:"El usuario no es un Administrador, por favor vuelva a iniciar sesión", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } ); }
            else{
                // guardamos en localStorage
                localStorage.setItem('infoUsuarioLocal', JSON.stringify({ usuario: response.data.usuarioEncontrado, autenticado: true}));

                // Creamos URL para redireccionar al home de usuario y Redireccionamos con los datos obtenidos del usuario
                var urlUsuario = "/admin/home/" + response.data.usuarioEncontrado.username;
                return navigate(urlUsuario);
            }
        });
    }


    return(
    <div>
        <main className="form-signin">
            <form>
            {/* Imagen y título */}
            <div className='d-flex justify-content-center'>
                <img className="mb-4" src="/imagenes/marca/nativeLogo.png" alt="" height="120" />
            </div>
            <h3 className="h3 mb-3 fw-normal text-light text-center">Iniciar sesión Administrador</h3>

            {/* usuario */}
            <div className="form-floating">
                <input onChange={handleChange} value={input.usuario} name="usuario" type="text" className="form-control"  placeholder="miusuario123" />
                <label htmlFor="floatingInput">Usuario</label>
            </div>


            {/* Contraseña  */}
            <div className="form-floating">
                <input onChange={handleChange} value={input.contraseña} name="contraseña" type="password" className="form-control" id="floatingPassword" placeholder="Password" />
                <label htmlFor="floatingPassword">contraseña</label>
            </div>

            {/* Botón enviar */}
            <button onClick={handleClick} className="btn btn-primary w-100 py-2" type="submit">Iniciar Sesión</button>


            {/* Versión y pretwor */}
            <div className='d-flex flex-column text-center mt-5'>
                <div ><p style={{color: '#b4b4b4'}} >Copyright &copy; 2023, Native. <b>V-1</b> </p></div>
                <div style={{margin: '-20px 0px 0px 0px'}} ><p style={{color: '#b4b4b4'}}><i className='enlaces'>Powered by</i><a style={{textDecoration: 'none', color: '#ada06b'}} href="/" ><i className='enlaces'> pretwor</i></a></p></div>
                <div style={{margin: '10px 0px 0px 0px'}} ><a style={{color: '#ada06b', textDecoration: 'none'}} href="/" >volver a Native</a></div>
            </div>


                
            </form>
        </main>
    </div>
    )
}



export default InicioSesionAdmin;