
function FooterUsuarioAdmin(){
    return(
       
      <div id="myFooterUsuarioAdmin" style={{bottom:"0% !important"}}>
        <footer>
            <p className="text-center py-2 parrafoFooter">Copyright 2023 &copy; Native.  <i className="parrafoFooter">  Powered by</i>
            <a className="parrafoFooter" href="https://pretwor.com/" target="_blank;" style={{ textDecoration:"none"}}> <i className="parrafoFooter">Pretwor</i></a>
            </p> 
        </footer>
        </div>
  
    )
}

export default FooterUsuarioAdmin;