import '../../estilos/paginas.css';
import { useEffect } from 'react';



function Privacidad() {
  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////
 
    
  return(
    <div className='contenedorPaginasUsuario'>
      
      

        {/* Primer contenedor: imagen principal */}
        <div className='contenedorPrivacidad'>
            <img className='logoContendorPrincipal' src='/imagenes/marca/nativeLogow.png' alt=''></img>
            <p className='subContenedorPrincipal'>Política de privacidad</p>
        </div>

        {/* contenido */}
        <div className='container my-5'>
          <div class="p-5 mb-4 bg-body-tertiary rounded-3">
              <div class="container-fluid py-5">
                  <h1 class="display-5 fw-bold">Política de privacidad</h1>
                  <p class="col-md-12">
                  Esta Política de privacidad describe cómo se recopila, utiliza y comparte su información personal cuando visita o hace una compra en www.native.com.co (el "Sitio").
                  <br/><br/>
                  INFORMACIÓN PERSONAL QUE RECOPILAMOS
                  <br/>
                  <br/>
                  Cuando visita el Sitio, recopilamos automáticamente cierta información sobre su dispositivo, incluida información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies que están instaladas en su dispositivo. Además, a medida que navega por el Sitio, recopilamos información sobre las páginas web individuales o los productos que usted ve, los sitios web o los términos de búsqueda que le remitieron al Sitio, y la información sobre cómo interactúa con el Sitio. Nos referimos a esta información recopilada automáticamente como "Información del dispositivo".
                  <br/>
                  Recopilamos información del dispositivo usando las siguientes tecnologías:
                  <br/>
                  - Las "cookies" son archivos de datos que se colocan en su dispositivo o computadora y a menudo incluyen un identificador único anónimo. Para obtener más información sobre las cookies y sobre cómo desactivar las cookies, visite http://www.allaboutcookies.org.
                  <br/>
                  - "Registrar archivos" rastrea las acciones que ocurren en el Sitio, y recopilar datos que incluyen su dirección IP, tipo de navegador, proveedor de servicios de Internet, páginas de referencia / salida y sellos de fecha / hora.
                  <br/>
                  - Las "balizas web", las "etiquetas" y los "píxeles" son archivos electrónicos que se utilizan para registrar información acerca de cómo navega por el Sitio.
                  <br/>
                  Además, cuando realiza una compra o intenta realizar una compra a través del Sitio, recopilamos cierta información de usted, incluidos su nombre, dirección de facturación, dirección de envío, información de pago, dirección de correo electrónico y número de teléfono. Nos referimos a esta información como "Información de pedido".
                  <br/>
                  <br/>
                  <br/>
                  Cuando hablamos de "Información personal" en esta Política de privacidad, estamos hablando tanto de Información del dispositivo como de Información del pedido.
                  <br/>
                  ¿COMO USAMOS TU INFORMACIÓN PERSONAL?
                  <br/>
                  Usamos la Información del pedido que recopilamos en general para cumplir con los pedidos realizados a través del Sitio (incluido el procesamiento de su información de pago, la organización del envío y la entrega de facturas y / o confirmaciones de pedido). Además, utilizamos esta información de pedido para:
                  - Comunicarse contigo;
                  - Examine nuestros pedidos de posibles riesgos o fraudes; y
                  - Cuando esté en línea con las preferencias que ha compartido con nosotros, proporcione información o publicidad relacionada con nuestros productos o servicios.
                  <br/>
                  <br/>
                  Usamos la información del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su dirección IP) y, en general, para mejorar y optimizar nuestro sitio (por ejemplo, al generar análisis sobre cómo nuestros clientes exploran e interactúan con el Sitio, y para evaluar el éxito de nuestras campañas de marketing y publicidad).
                  <br/>
                  <br/>
                  COMPARTIR SU INFORMACIÓN PERSONAL
                  <br/>
                  Compartimos su información personal con terceros para ayudarnos a usar su información personal, como se describe anteriormente. Por ejemplo, también utilizamos Google Analytics para ayudarnos a comprender cómo usan nuestros clientes el Sitio; puede obtener más información sobre cómo Google usa su Información personal <a href='https://www.google.com/intl/es/policies/privacy'>aquí</a>. También puede darse de baja de Google Analytics <a href=' https://tools.google.com/dlpage/gaoptout'>aquí</a>.
                  <br/>
                  Finalmente, también podemos compartir su información personal para cumplir con las leyes y regulaciones aplicables, para responder a una citación, orden de registro u otra solicitud legal de información que recibimos, o para proteger nuestros derechos de otra manera.
                  <br/>
                  <br/>
                  PUBLICIDAD CONDUCTUAL
                  <br/>
                  <br/>
                  Como se describió anteriormente, utilizamos su Información personal para proporcionarle anuncios específicos o comunicaciones de marketing que creemos que pueden ser de su interés. Para obtener más información sobre cómo funciona la publicidad dirigida, puede visitar la página educativa de la Iniciativa publicitaria de la red ("NAI").
                  <br/>
                  Puede optar por excluirse de la publicidad dirigida mediante los siguientes enlaces:
                  <br/>
                  - <a href='https://www.facebook.com/settings/?tab=ads'>Facebook</a>
                  <br/>
                  - <a href='https://www.google.com/settings/ads/anonymous'>Google</a>
                  <br/>
                  - <a href='https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads'>Bing</a>
                  <br/>
                  <br/>
                  Además, puede optar por excluirse de algunos de estos servicios visitando el portal de exclusión voluntaria de Digital Advertising Alliance en: http://optout.aboutads.info/.
                  <br/>
                  <br/>
                  NO SEGUIR
                  <br/>
                  Tenga en cuenta que no alteramos las prácticas de recopilación y uso de datos de nuestro Sitio cuando vemos una señal de No rastrear desde su navegador.
                  <br/>
                  <br/>
                  TUS DERECHOS
                  Si usted es residente en Europa, tiene derecho a acceder a la información personal que tenemos sobre usted y a solicitar que se corrija, actualice o elimine su información personal. Si desea ejercer este derecho, comuníquese con nosotros a través de la información de contacto a continuación.
                  <br/>
                  Además, si usted es un residente europeo, notamos que estamos procesando su información para cumplir con los contratos que podamos tener con usted (por ejemplo, si realiza un pedido a través del Sitio), o de lo contrario perseguir nuestros intereses comerciales legítimos enumerados anteriormente. Además, tenga en cuenta que su información será transferida fuera de Europa, incluyendo a Canadá y los Estados Unidos.
                  <br/>
                  <br/>
                  RETENCIÓN DE DATOS
                  Cuando realiza un pedido a través del Sitio, mantendremos su Información de Pedido para nuestros registros a menos y hasta que nos solicite que eliminemos esta información.
                  <br/>
                  <br/>
                  CAMBIOS
                  Podemos actualizar esta política de privacidad de vez en cuando para reflejar, por ejemplo, cambios en nuestras prácticas o por otros motivos operativos, legales o reglamentarios.
                  <br/>
                  <br/>
                  <br/>
                  CONTÁCTENOS
                  Para obtener más información acerca de nuestras prácticas de privacidad, si tiene alguna pregunta o desea realizar un reclamo, comuníquese con nosotros por correo electrónico a contacto@native.com.co o por correo utilizando los detalles que se brindan a continuación:
                  <br/>
                  NATIVE
                  [Re: Oficial de Cumplimiento de Privacidad]
                  </p>
              </div>
          </div>
        </div>
    </div>
  )
}



export default Privacidad;